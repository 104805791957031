import { useMemo } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

// page
import FloatingIslandInformation from "./FloatingIsland/InformationPage";
import MysticIslandInformation from "./MysticIsland/InformationPage";
import DarkCityInformation from "./DarkCity/InformationPage";
import ParallelUniverseInformation from "./ParallelUniverse/InformationPage";
import RadishIslandInformation from "./RadishIsland/InformationPage";
// utils
import { themeEnum } from "../../utils/localData";

// SECTION apollo
// NOTE 活動
const GET_EVENT = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      ... on RaisingEvent {
        # "主題"
        theme
      }
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
export default function InformationMainPage() {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const eventId = sessionStorage.getItem("luyao_raisingEvent_id");

  const { data: eventData, loading: eventLoading } = useQuery(GET_EVENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      id: Number(id) || Number(eventId),
    },
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          sessionStorage.setItem(
            "luyao_raisingEvent_version",
            themeEnum[event.theme] || "FloatingIsland"
          );
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  const getEventTheme = useMemo(
    () => themeEnum[eventData?.event?.theme] || "FloatingIsland",
    [eventData]
  );

  if (eventLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (getEventTheme === "FloatingIsland") {
    return <FloatingIslandInformation />;
  } else if (getEventTheme === "MysticIsland") {
    return <MysticIslandInformation />;
  } else if (getEventTheme === "DarkCity") {
    return <DarkCityInformation />;
  } else if (getEventTheme === "ParallelUniverse") {
    return <ParallelUniverseInformation />;
  } else if (getEventTheme === "RadishIsland") {
    return <RadishIslandInformation />;
  }
  return null;
}
