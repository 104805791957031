import FloatingIslandPurchasePage from "./FloatingIsland/PurchasePage";
import MysticIslandPurchasePage from "./MysticIsland/PurchasePage";
import DarkCityPurchasePage from "./DarkCity/PurchasePage";
import ParallelUniversePurchasePage from "./ParallelUniverse/PurchasePage";
import RadishIslandPurchasePage from "./RadishIsland/PurchasePage";

export default function PurchaseMainPage() {
  const version =
    sessionStorage.getItem("luyao_raisingEvent_version") || "FloatingIsland";

  if (version === "FloatingIsland") {
    return <FloatingIslandPurchasePage />;
  } else if (version === "MysticIsland") {
    return <MysticIslandPurchasePage />;
  } else if (version === "DarkCity") {
    return <DarkCityPurchasePage />;
  } else if (version === "ParallelUniverse") {
    return <ParallelUniversePurchasePage />;
  } else if (version === "RadishIsland") {
    return <RadishIslandPurchasePage />;
  }
  return null;
}
