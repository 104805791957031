import React, { useState, useEffect, memo, useMemo } from "react";
// material-ui
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Card,
  useTheme,
  Typography,
  Divider,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
// moment
import moment from "moment";
// apollo
import { useQuery, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-router-dom
import { useHistory } from "react-router-dom";
// react-helmet
import { Helmet } from "react-helmet";

// component
import SquareBox from "../component/SquareBox";
import DateCountdown from "../component/DateCountdown";
import Products from "../component/Products";
// icon
import { ReactComponent as LogoIcon } from "../logo.svg";
// utils
import { themeEnum } from "../utils/localData";
import judgeEventType from "../utils/judgeEventType";
import emptyArray from "../utils/emptyArray";

const GET_EVENTS = gql`
  query events(
    $latest: Boolean
    $pageSize: Int
    $page: Int
    $activated: Boolean
    $eventTypes: [EventType]
  ) {
    events(
      front: true
      latest: $latest
      pageSize: $pageSize
      page: $page
      activated: $activated
      eventTypes: $eventTypes
    ) {
      eventCount
      pageCount
      events {
        id
        type
        name
        product {
          id
          images {
            filename
            mimetype
            encoding
            location
          }
          name
          size
          material
          stock
          price
          depositSize
        }
        startTime
        endTime
        ... on DrawingLotsEvent {
          # "已達人數上限"
          reachedMaxNumberOfPeople
          drew
          participants {
            id
          }
          # "參加人數上限"
          maxNumberOfPeople
        }
        ... on AuctionEvent {
          winningBidder {
            id
          }
          newestBid
          bidResult
        }
        ... on RaidEvent {
          players {
            id
          }
        }
        ... on RaisingEvent {
          # "開始時間"
          startTime
          # "結束時間"
          endTime
          # "已上架"
          activated
          # "封面url"
          coverImageUrl
          # "主題"
          theme
        }
      }
    }
  }
`;

//團體戰Query
const GET_EVENT_RAIDEVENT_ENABLED = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      ... on RaidEvent {
        enabled
      }
    }
  }
`;

export default function Events({ eventType }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
  });
  const classes = useStyles();

  function getEventPage(eventType) {
    switch (eventType) {
      case "drawingLots":
      default:
        return <DrawingLotsEvent />;
      case "productsOfPasswordRequired":
        return (
          <>
            <Helmet>
              <title>社團限定活動商品｜路遙圓創</title>
            </Helmet>
            <Grid container spacing={2}>
              <Products type={"CLUB_EXCLUSIVE"} />
            </Grid>
          </>
        );
      case "raid":
        return <RaidEvent />;
      case "raising_event":
        return <RaisingEvent />;
    }
  }

  return (
    <div>
      <Container className={classes.container}>
        {getEventPage(eventType)}
      </Container>
    </div>
  );
}

const DrawingLotsEvent = memo(function DrawingLotsEvent() {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
  });
  const classes = useStyles();

  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
      pageSize: 10,
      page,
      activated: true,
      eventTypes: ["DRAWING_LOTS_EVENT", "FLASH_SALE_EVENT"],
    },
    onError(err) {
      return null;
    },
  });

  return (
    <Container className={classes.container}>
      <Helmet>
        <title>抽選活動商品｜路遙圓創</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} style={{ display: "none" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding={`${theme.spacing(3)}px ${theme.spacing(1)}px`}
          >
            <Box>
              <Box display="flex">
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  LUYAO
                </Typography>
                <LogoIcon style={{ height: 56, width: 56 }} />
              </Box>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                DESIGN
              </Typography>
            </Box>
            <Box margin={`${theme.spacing(3)}px 0`}>
              <Typography variant="h6">Drawing Lots Event Products</Typography>
              <Box
                bgcolor={theme.palette.primary.main}
                padding={`0 ${theme.spacing(2)}px`}
                color={theme.palette.primary.contrastText}
              >
                <Typography variant="h6" align="center">
                  抽選活動商品
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                TAIWAN
              </Typography>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                DESIGN TOYS
              </Typography>
            </Box>
          </Box>
        </Grid>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="450px"
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {Boolean(data) &&
              data.events.events.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                  <ProductItem data={item} />
                </Grid>
              ))}
            {Boolean(data?.events.events[0]) && (
              <Grid container item justify="center">
                <Pagination
                  count={
                    Boolean(data)
                      ? data.events.pageCount
                        ? data.events.pageCount
                        : 1
                      : 1
                  }
                  size="large"
                  color="primary"
                  page={page}
                  onChange={(e, n) => {
                    window.scrollTo(0, 0);
                    setPage(n);
                  }}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
});

// const AuctionEvent = memo(function AuctionEvent() {
//   const theme = useTheme();
//   const useStyles = makeStyles({
//     container: {
//       padding: theme.spacing(2),
//     },
//   });
//   const classes = useStyles();

//   const [page, setPage] = useState(1);

//   const { data, loading } = useQuery(GET_EVENTS, {
//     fetchPolicy: "network-only",
//     notifyOnNetworkStatusChange: true,
//     variables: {
//       latest: true,
//       pageSize: 10,
//       page,
//       activated: true,
//       eventTypes: ["FLASH_SALE_EVENT"],
//     },
//     onError() {
//       return null;
//     },
//   });

//   return (
//     <Container className={classes.container}>
//       <Helmet>
//         <title>限量活動商品｜路遙圓創</title>
//       </Helmet>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={12} md={12} lg={6} style={{ display: "none" }}>
//           <Box
//             display="flex"
//             flexDirection="column"
//             alignItems="flex-start"
//             padding={`${theme.spacing(3)}px ${theme.spacing(1)}px`}
//           >
//             <Box>
//               <Box display="flex">
//                 <Typography variant="h3" style={{ fontWeight: "bold" }}>
//                   LUYAO
//                 </Typography>
//                 <LogoIcon style={{ height: 56, width: 56 }} />
//               </Box>
//               <Typography variant="h3" style={{ fontWeight: "bold" }}>
//                 DESIGN
//               </Typography>
//             </Box>
//             <Box margin={`${theme.spacing(3)}px 0`}>
//               <Typography variant="h6">Flach Sale Event Products</Typography>
//               <Box
//                 bgcolor={theme.palette.primary.main}
//                 padding={`0 ${theme.spacing(2)}px`}
//                 color={theme.palette.primary.contrastText}
//               >
//                 <Typography variant="h6" align="center">
//                   限量活動商品
//                 </Typography>
//               </Box>
//             </Box>
//             <Box>
//               <Typography variant="h3" style={{ fontWeight: "bold" }}>
//                 TAIWAN
//               </Typography>
//               <Typography variant="h3" style={{ fontWeight: "bold" }}>
//                 DESIGN TOYS
//               </Typography>
//             </Box>
//           </Box>
//         </Grid>
//         {loading ? (
//           <Box
//             display="flex"
//             alignItems="center"
//             justifyContent="center"
//             height="450px"
//             width="100%"
//           >
//             <CircularProgress />
//           </Box>
//         ) : (
//           <>
//             {Boolean(data) &&
//               data.events.events.map((item) => (
//                 <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
//                   <ProductItem data={item} />
//                 </Grid>
//               ))}
//             {Boolean(data?.events.events[0]) && (
//               <Grid container item justify="center">
//                 <Pagination
//                   count={
//                     Boolean(data)
//                       ? data.events.pageCount
//                         ? data.events.pageCount
//                         : 1
//                       : 1
//                   }
//                   size="large"
//                   color="primary"
//                   page={page}
//                   onChange={(e, n) => {
//                     window.scrollTo(0, 0);
//                     setPage(n);
//                   }}
//                   showFirstButton
//                   showLastButton
//                 />
//               </Grid>
//             )}
//           </>
//         )}
//       </Grid>
//     </Container>
//   );
// });

const RaidEvent = memo(function RaidEvent() {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
  });
  const classes = useStyles();

  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
      pageSize: 10,
      page,
      activated: true,
      eventTypes: ["RAID_EVENT"],
    },
    onError() {
      return null;
    },
  });

  return (
    <Container className={classes.container}>
      <Helmet>
        <title>團體戰活動商品｜路遙圓創</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} style={{ display: "none" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding={`${theme.spacing(3)}px ${theme.spacing(1)}px`}
          >
            <Box>
              <Box display="flex">
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  LUYAO
                </Typography>
                <LogoIcon style={{ height: 56, width: 56 }} />
              </Box>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                DESIGN
              </Typography>
            </Box>
            <Box margin={`${theme.spacing(3)}px 0`}>
              <Typography variant="h6">Raid Event Products</Typography>
              <Box
                bgcolor={theme.palette.primary.main}
                padding={`0 ${theme.spacing(2)}px`}
                color={theme.palette.primary.contrastText}
              >
                <Typography variant="h6" align="center">
                  團體戰活動商品
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                TAIWAN
              </Typography>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                DESIGN TOYS
              </Typography>
            </Box>
          </Box>
        </Grid>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="450px"
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {Boolean(data) &&
              data.events.events.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                  <ProductItem data={item} />
                </Grid>
              ))}
            {Boolean(data?.events.events[0]) && (
              <Grid container item justify="center">
                <Pagination
                  count={
                    Boolean(data)
                      ? data.events.pageCount
                        ? data.events.pageCount
                        : 1
                      : 1
                  }
                  size="large"
                  color="primary"
                  page={page}
                  onChange={(e, n) => {
                    window.scrollTo(0, 0);
                    setPage(n);
                  }}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
});

const RaisingEvent = memo(function RaisingEvent() {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
  });
  const classes = useStyles();

  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
      pageSize: 12,
      page,
      eventTypes: ["RAISING_EVENT"],
    },
    onError(err) {
      console.log("err", err);
      return null;
    },
  });

  const raisingEventList = useMemo(
    () =>
      emptyArray(data?.events?.events).filter(
        (item) =>
          (moment(item.startTime).isAfter() && item.activated) ||
          moment(new Date()).isBetween(item.startTime, item.endTime) ||
          moment(item.endTime).isBefore()
      ),
    [data]
  );

  return (
    <Container className={classes.container}>
      <Helmet>
        <title>育成專區｜路遙圓創</title>
      </Helmet>
      <Grid container spacing={2}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="450px"
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {raisingEventList.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                <ProductItem data={item} />
              </Grid>
            ))}
            {raisingEventList.length > 0 && (
              <Grid container item justify="center">
                <Pagination
                  count={
                    Boolean(data)
                      ? data.events.pageCount
                        ? data.events.pageCount
                        : 1
                      : 1
                  }
                  size="large"
                  color="primary"
                  page={page}
                  onChange={(e, n) => {
                    window.scrollTo(0, 0);
                    setPage(n);
                  }}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
});

function ProductItem({ data }) {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    contentArea: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        margin: `${theme.spacing(1)}px 0`,
      },
    },
    divider: {
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
    titleText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 3 /* number of lines to show */,
      boxOrient: "vertical",
      height: 96,
    },
    infoText: {
      textAlign: "left",
      color: theme.palette.text.hint,
    },
    itemButton: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "& > *": {
        width: "100%",
      },
    },
    countdownText: {
      textAlign: "center",
      color: theme.palette.text.hint,
    },
  }));
  const classes = useStyles();
  const [eventStartBefore, setEventStartBefore] = useState(false);
  const [eventStart, setEventStart] = useState(false);

  useEffect(() => {
    if (!moment(data.startTime).isBefore()) {
      setEventStartBefore(true);
    } else if (!moment(data.endTime).isBefore()) {
      setEventStart(true);
    }
  }, [data.startTime, data.endTime]);

  function _goToProduct() {
    switch (data.type) {
      case "RAID_EVENT":
      case "PVP_RAID_EVENT":
        return history.push(`/event/raid/${data.id}`);
      // case "AUCTION_EVENT":
      //   return history.push(`/event/auction/${data.id}`);
      case "DRAWING_LOTS_EVENT":
        return history.push(`/event/drawingLots/${data.id}`);
      case "RAISING_EVENT":
        if (data.theme) {
          sessionStorage.setItem(
            "luyao_raisingEvent_version",
            themeEnum[data.theme]
          );
        } else {
          sessionStorage.removeItem("luyao_raisingEvent_version");
        }
        return history.push(`/special-event/information?id=${data.id}`);
      default:
        return null;
    }
  }

  const [
    getEventRaideventEnabled,
    { data: eventRaidEventEnabledData, startPolling, stopPolling },
  ] = useLazyQuery(GET_EVENT_RAIDEVENT_ENABLED, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (data.type === "RAID_EVENT" || data.type === "PVP_RAID_EVENT") {
      getEventRaideventEnabled({ variables: { id: data.id } });
    }
  }, [data, getEventRaideventEnabled]);

  useEffect(() => {
    if (startPolling) {
      startPolling(1000);
    }
    return () => {
      if (!eventStart) {
        if (stopPolling) {
          stopPolling();
        }
      }
    };
  }, [eventStart, startPolling, stopPolling]);

  function getTypeContext(value) {
    switch (value.type) {
      case "DRAWING_LOTS_EVENT":
        return (
          <Typography className={classes.infoText}>
            {typeof data.drew === "boolean" && !data.drew ? "目前" : ""}
            參加人數：{data.participants.length}
          </Typography>
        );
      case "AUCTION_EVENT":
        if (!Boolean(data.winningBidder)) {
          return (
            <Typography className={classes.infoText}>
              目前最高金額：{data.newestBid}
            </Typography>
          );
        } else {
          return (
            <Typography className={classes.infoText}>
              得標金額：{data.bidResult}
            </Typography>
          );
        }
      case "RAID_EVENT":
      case "PVP_RAID_EVENT":
        return (
          <Typography className={classes.infoText}>
            {typeof eventRaidEventEnabledData?.event?.enabled === "boolean" &&
            eventRaidEventEnabledData.event.enabled
              ? "目前"
              : ""}
            參加人數：{data.players.length}
          </Typography>
        );
      default:
        return "";
    }
  }

  const isNotRaisingEvent = data?.type !== "RAISING_EVENT";

  return (
    <ButtonBase className={classes.itemButton} onClick={_goToProduct}>
      <Card variant="outlined">
        <SquareBox>
          <img
            src={
              emptyArray(data?.product?.images)[0]?.location ||
              (data?.type === "RAISING_EVENT" &&
                (data.coverImageUrl ||
                  require("../images/Breeding_activity_Birth_logo.jpg")
                    .default)) ||
              require("../images/@luyaoLogo-new.jpg").default
            }
            className={classes.image}
            alt=""
          />
        </SquareBox>
      </Card>
      <Box className={classes.contentArea}>
        <div>
          <Typography variant="h6" align="left" className={classes.titleText}>
            {data.name}
          </Typography>
          {isNotRaisingEvent && <Divider className={classes.divider} />}
        </div>
        {isNotRaisingEvent && (
          <Box
            height={72}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {getTypeContext(data)}
          </Box>
        )}
      </Box>
      <PriceBrand
        enabled={eventRaidEventEnabledData?.event?.enabled}
        soldOut={Boolean(
          moment(data.endTime).isBefore() ||
            data?.drew ||
            (data.type === "RAISING_EVENT" && !data?.activated)
        )}
        reachedMaxNumberOfPeople={Boolean(data.reachedMaxNumberOfPeople)}
        type={data.type}
        maxNumberOfPeople={data.maxNumberOfPeople}
      />
      {(() => {
        const didNotEndEarly =
          (data.type === "DRAWING_LOTS_EVENT" &&
            typeof data.drew === "boolean" &&
            !data.drew) ||
          (data.type === "AUCTION_EVENT" && !Boolean(data.winningBidder)) ||
          ((data.type === "RAID_EVENT" || data.type === "PVP_RAID_EVENT") &&
            typeof eventRaidEventEnabledData?.event?.enabled === "boolean" &&
            eventRaidEventEnabledData.event.enabled) ||
          (data.type === "RAISING_EVENT" &&
            (moment(data.startTime).isAfter() ||
              moment(new Date()).isBetween(data.startTime, data.endTime)) &&
            data.activated);
        if (didNotEndEarly && eventStartBefore) {
          return (
            <DateCountdown
              start={moment(data.startTime)}
              onEnd={() =>
                setEventStartBefore(() => {
                  setEventStart(true);
                  return false;
                })
              }
            />
          );
        } else {
          if (didNotEndEarly && eventStart) {
            return (
              <>
                {moment(data.endTime).isAfter() && (
                  <DateCountdown
                    end={moment(data.endTime)}
                    onEnd={() => setEventStart(false)}
                  />
                )}
              </>
            );
          }
        }
      })()}
    </ButtonBase>
  );
}
function PriceBrand({
  enabled,
  soldOut,
  reachedMaxNumberOfPeople = false,
  type,
  maxNumberOfPeople,
}) {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      padding={`0 ${theme.spacing(2)}px`}
      color={theme.palette.primary.contrastText}
    >
      <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
        {soldOut || (typeof enabled === "boolean" && !enabled)
          ? "已結束"
          : reachedMaxNumberOfPeople
          ? "已達人數上限"
          : judgeEventType({ type, maxNumberOfPeople })}
      </Typography>
    </Box>
  );
}
