import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  InputAdornment,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { Controller, useForm, FormProvider } from "react-hook-form";
// apollo
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-player
import ReactPlayer from "react-player";

// component
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import {
  CustomNumberInput,
  CustomTextField,
  InputTitle,
} from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import AdaptiveImage from "../../component/AdaptiveImage";
import CustomUploadFiles from "../../component/CustomUploadFiles";

// SECTION apollo
// NOTE 小遊戲設定
const GET_GAME_CONFIG = gql`
  query gameConfig {
    gameConfig {
      id
      # "主影片網址"
      mainVideoUrl
      # "結果1影片網址"
      resultVideoUrl1
      # "結果1機率"
      rate1
      # "結果2影片網址"
      resultVideoUrl2
      # "結果2機率"
      rate2
      # "結果3影片網址"
      resultVideoUrl3
      # "結果3機率"
      rate3
      # "結果4影片網址"
      resultVideoUrl4
      # "結果4機率"
      rate4
      # "結果5影片網址"
      resultVideoUrl5
      # "結果5機率"
      rate5
      # "結果6影片網址"
      resultVideoUrl6
      # "結果6機率"
      rate6
      # "結果7影片網址"
      resultVideoUrl7
      # "結果7機率"
      rate7
      # "密碼"
      password
    }
  }
`;
// NOTE 儲存小遊戲設定
const SAVE_GAME_CONFIG = gql`
  mutation saveGameConfig($gameConfigInput: GameConfigInput!) {
    saveGameConfig(gameConfigInput: $gameConfigInput) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

export default function ShootingGameForm() {
  const { notice } = useAlert();
  const form = useForm({
    defaultValues: {
      mainVideoUrl: "",
      resultVideoUrl1: "",
      rate1: "",
      resultVideoUrl2: "",
      rate2: "",
      resultVideoUrl3: "",
      rate3: "",
      resultVideoUrl4: "",
      rate4: "",
      resultVideoUrl5: "",
      rate5: "",
      resultVideoUrl6: "",
      rate6: "",
      resultVideoUrl7: "",
      rate7: "",
      password: "",
    },
  });
  const { control, handleSubmit, reset } = form;
  const [realPassword, setRealPassword] = useState("");
  const {
    data: gameConfigData,
    loading: gameConfigLoading,
    refetch,
  } = useQuery(GET_GAME_CONFIG, {
    fetchPolicy: "network-only",
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (gameConfigData?.gameConfig) {
      const { __typename, id, password, ...otherGameConfig } =
        gameConfigData.gameConfig;
      setRealPassword(password);
      reset({ ...otherGameConfig, password });
    }
  }, [gameConfigData, reset]);
  const [saveGameConfigFn, { loading: saveGameConfigLoading }] = useMutation(
    SAVE_GAME_CONFIG,
    {
      onCompleted({ saveGameConfig }) {
        if (saveGameConfig.success) {
          notice("儲存成功");
          refetch();
        } else if (saveGameConfig.message) {
          notice(saveGameConfig.message);
        }
      },
      onError() {
        return null;
      },
    }
  );
  const _saveGameConfig = useCallback(
    ({
      resultVideoUrl1,
      resultVideoUrl2,
      resultVideoUrl3,
      resultVideoUrl4,
      resultVideoUrl5,
      resultVideoUrl6,
      resultVideoUrl7,
      rate1,
      rate2,
      rate3,
      rate4,
      rate5,
      rate6,
      rate7,
      password,
      ...otherDatas
    }) => {
      const rates = [rate1, rate2, rate3, rate4, rate5, rate6, rate7].map(
        Number
      );
      const totalRate = rates.reduce((sum, rate) => sum + (rate || 0), 0);

      if (totalRate !== 100) {
        return notice("結果機率不等於100。");
      }

      const resultVideoUrls = [
        resultVideoUrl1,
        resultVideoUrl2,
        resultVideoUrl3,
        resultVideoUrl4,
        resultVideoUrl5,
        resultVideoUrl6,
        resultVideoUrl7,
      ];

      const gameConfigInput = {
        ...otherDatas,
        resultVideoUrl1,
        resultVideoUrl2: null,
        resultVideoUrl3: null,
        resultVideoUrl4: null,
        resultVideoUrl5: null,
        resultVideoUrl6: null,
        resultVideoUrl7: null,
        rate1: rates[0],
        rate2: null,
        rate3: null,
        rate4: null,
        rate5: null,
        rate6: null,
        rate7: null,
        password: password || null,
      };

      rates.forEach((rate, index) => {
        if (rate > 0) {
          gameConfigInput[`rate${index + 1}`] = rate;
          gameConfigInput[`resultVideoUrl${index + 1}`] =
            resultVideoUrls[index];
        }
      });

      saveGameConfigFn({ variables: { gameConfigInput } });
    },
    [saveGameConfigFn, notice]
  );
  const getGameUrl = useCallback((password) => {
    const port = window.location.port ? `:${window.location.port}` : "";
    const passwordUrl = password ? `?token=${password}` : "";
    const domain =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      "/shooting-game" +
      passwordUrl;
    return domain;
  }, []);
  if (gameConfigLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <FormProvider {...form}>
      <Card>
        <LoadingModal loading={saveGameConfigLoading} />
        <CardContent>
          <Typography>小遊戲設定</Typography>
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>小遊戲網址：{getGameUrl(realPassword)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="password"
                    render={({ field }) => (
                      <div>
                        <InputTitle label="密碼" />
                        <CustomTextField {...field} fullWidth />
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="mainVideoUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="待機動畫(影片)" required />
                    <AdaptiveImage ratio="75%">
                      <CustomUploadFiles
                        name="mainVideoUrl"
                        multiple={false}
                        accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                        type="video"
                        render={() => (
                          <ReactPlayer
                            url={field?.value}
                            playing
                            width={"100%"}
                            height="100%"
                            controls
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl1"
                  rules={{
                    required: "必填欄位 !",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果1影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl1"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate1"
                  rules={{
                    required: "必填欄位",
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果1機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl2"
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果2影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl2"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate2"
                  rules={{
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果2機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl3"
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果3影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl3"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate3"
                  rules={{
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果3機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl4"
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果4影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl4"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate4"
                  rules={{
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果4機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl5"
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果5影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl5"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate5"
                  rules={{
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果5機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl6"
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果6影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl6"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate6"
                  rules={{
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果6機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: 16,
                }}
              >
                <Controller
                  name="resultVideoUrl7"
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <InputTitle label="結果7影片" required />
                      <AdaptiveImage ratio="75%">
                        <CustomUploadFiles
                          name="resultVideoUrl7"
                          multiple={false}
                          accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                          type="video"
                          render={() => (
                            <ReactPlayer
                              url={field?.value}
                              playing
                              width={"100%"}
                              height="100%"
                              controls
                            />
                          )}
                          uploadIconStyle={{
                            border: `1px solid ${
                              Boolean(error) ? "red" : "#9c9c9c"
                            }`,
                            borderRadius: "5px",
                          }}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.location);
                          }}
                        />
                      </AdaptiveImage>
                      {error?.message && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="rate7"
                  rules={{
                    validate: (e) => {
                      if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                        return "只允許 0～100 的整數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      label="結果7機率"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            onPress={handleSubmit(_saveGameConfig)}
            variant="contained"
            color="primary"
          >
            儲存
          </Button>
        </CardActions>
      </Card>
    </FormProvider>
  );
}
