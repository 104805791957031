import React, {
  useEffect,
  useRef,
  useState,
  Fragment,
  useCallback,
} from "react";
import {
  Grid,
  Chip,
  Box,
  CircularProgress,
  FormLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { useForm, FormProvider } from "react-hook-form";
// NOTE 工具
import { eventTypeList, eventTypeEnum } from "../../utils/localData";
import { client } from "../../provider/Apollo";
import emptyArray from "../../utils/emptyArray";
import removeDuplicates from "../../utils/removeDuplicates";
// NOTE 組件
import CustomDropdown from "../../component/Custom/Dropdown";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import {
  AutocompleteSelect,
  TextInput,
  NumberInput,
} from "../../component/Form";
import LoadingModal from "../../component/LoadingModal";
import Button from "../../component/Button";
import CheckboxesTags from "../../component/MultipleAutoComplate/CheckboxesTags";

const GET_PRODUCTS_ALL = gql`
  query products {
    products {
      products {
        value: id
        # "名稱"
        label: name
      }
    }
  }
`;

const GET_MEMBERS = gql`
  query members($productIds: [Int], $searchTerm: String, $hidden: Boolean) {
    members(productIds: $productIds, searchTerm: $searchTerm, hidden: $hidden) {
      members {
        value: id
        label: fullName
      }
    }
  }
`;

const GET_EVENT = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      type
      name
      product {
        id
        name
      }
      startTime
      endTime
      activated
      memberTierLimit
      createdAt
      # "繳費期限(分鐘)"
      paymentMinuteLimit
      ... on DrawingLotsEvent {
        quota
        # "參加人數上限"
        maxNumberOfPeople
        showQuota
        reservations {
          id
          fullName
        }
      }
      ... on AuctionEvent {
        bidStartAt
        bidIncrement
        bidLimitPerBidding
      }
      ... on RaidEvent {
        monster
        showQuota
        maxHealthPoint
        damagePerAttack
        quota
      }
    }
  }
`;

const SAVE_DRAWINGLOTSEVENT = gql`
  mutation saveDrawingLotsEvent(
    $drawingLotsEventInput: DrawingLotsEventInput!
  ) {
    saveDrawingLotsEvent(drawingLotsEventInput: $drawingLotsEventInput) {
      success
      message
    }
  }
`;

const SAVE_AUCTIONEVENT = gql`
  mutation saveAuctionEvent($auctionEventInput: AuctionEventInput!) {
    saveAuctionEvent(auctionEventInput: $auctionEventInput) {
      success
      message
    }
  }
`;

const SAVE_RAIDEVENT = gql`
  mutation saveRaidEvent($raidEventInput: RaidEventInput!) {
    saveRaidEvent(raidEventInput: $raidEventInput) {
      success
      message
    }
  }
`;

const ACTIVATEEVENT = gql`
  mutation activateEvent($eventId: Int!, $activated: Boolean!) {
    activateEvent(eventId: $eventId, activated: $activated) {
      success
      message
    }
  }
`;
// NOTE 活動預設名單
/** - 活動預設名單 */
const EVENT_DEFAULT_LIST = gql`
  query eventDefaultList($productIds: [Int]) {
    eventDefaultList(productIds: $productIds) {
      # "白名單"
      whitelist {
        value: id
        label: fullName
      }
    }
  }
`;

const activatedArray = [
  {
    label: "上架",
    value: true,
  },
  {
    label: "下架",
    value: false,
  },
];

export default function EventForm({ id, products, onChangeSelect = () => {} }) {
  const Alert = useAlert();
  const [activated, setActivated] = useState(true);
  const [activatedObject, setActivatedObject] = useState({});
  const [type, setType] = useState("DRAWING_LOTS_EVENT");
  const [typeObject, setTypeObject] = useState({
    label: "抽選活動",
    value: "DRAWING_LOTS_EVENT",
  });
  const [name, setName] = useState("");
  const [productId, setProductId] = useState(null);
  const [productObject, setProductObject] = useState({});
  const [maxHealthPoint, setMaxHealthPoint] = useState(null);
  const [damagePerAttack, setDamagePerAttack] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [quota, setQuota] = useState(null);
  const quotaError = useRef(false);
  const [maxNumberOfPeople, setMaxNumberOfPeople] = useState(null);
  const maxNumberOfPeopleError = useRef(false);
  const [reservationIds, setReservationIds] = useState([]);
  const [bidStartAt, setBidStartAt] = useState(null);
  const [bidIncrement, setBidIncrement] = useState(null);
  const [bidLimitPerBidding, setBidLimitPerBidding] = useState(null);
  const [memberTierLimitObject, setMemberTierLimitObject] = useState(null);
  const [showQuota, setShowQuota] = useState("");
  const [pvpMode, setPvpMode] = useState(false);
  const [paymentMinuteLimit, setPaymentMinuteLimit] = useState(null);
  const paymentMinuteLimitErrorRef = useRef(false);
  const form = useForm({
    defaultValues: {
      productIds: [],
    },
  });
  const { setValue, getValues } = form;

  const { data: productsAllData } = useQuery(GET_PRODUCTS_ALL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  function getMemberTier(value) {
    switch (value) {
      case "HORSE_POWER":
        return { label: "小馬力", value: "HORSE_POWER" };
      case "SUPER_HORSE_POWER":
        return { label: "超級馬力", value: "SUPER_HORSE_POWER" };
      case "COLLECTOR_HORSE_POWER":
        return { label: "藏家馬力", value: "COLLECTOR_HORSE_POWER" };
      default:
        return null;
    }
  }

  const [getEvent, { loading: eventLoading }] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          const isPVPRaid = event.type === "PVP_RAID_EVENT";
          const dataForScreen = isPVPRaid
            ? { label: "團體戰活動", value: "RAID_EVENT" }
            : {
                label: eventTypeEnum[event.type],
                value: event.type,
              };
          setType(dataForScreen.value);
          setTypeObject(dataForScreen);
          if (isPVPRaid) {
            setPvpMode(true);
          }
          setName(event.name);
          const hasProduct = products.find(
            (item) => item.value === event.product.id
          );
          if (Boolean(hasProduct)) {
            setProductId(hasProduct.value);
            setProductObject(hasProduct);
          }
          setStartTime(event.startTime);
          setEndTime(event.endTime);
          setQuota(event.quota);
          setMaxNumberOfPeople(event.maxNumberOfPeople);
          setPaymentMinuteLimit(event.paymentMinuteLimit);
          const newReservations = [];
          for (let i = 0; i < event.reservations?.length; i++) {
            newReservations.push({
              label: event.reservations[i].fullName,
              value: event.reservations[i].id,
            });
          }
          if (event.activated) {
            setActivatedObject({
              label: "上架",
              value: true,
            });
          } else {
            setActivatedObject({
              label: "下架",
              value: false,
            });
          }
          setReservationIds(newReservations);
          setBidStartAt(event.bidStartAt);
          setBidIncrement(event.bidIncrement);
          setBidLimitPerBidding(event.bidLimitPerBidding);
          setMaxHealthPoint(event.maxHealthPoint);
          setDamagePerAttack(event.damagePerAttack);
          setMemberTierLimitObject(getMemberTier(event.memberTierLimit));
          if (event.showQuota) {
            setShowQuota(event.showQuota);
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getEvent({ variables: { id } });
    } else {
      setType("DRAWING_LOTS_EVENT");
      setTypeObject({
        label: "抽選活動",
        value: "DRAWING_LOTS_EVENT",
      });
      setName("");
      setProductId(null);
      setProductObject({});
      setStartTime(null);
      setEndTime(null);
      setQuota(null);
      setReservationIds([]);
      setBidStartAt(null);
      setBidIncrement(null);
      setBidLimitPerBidding(null);
      setMemberTierLimitObject(null);
      setPaymentMinuteLimit(null);
      setShowQuota("");
    }
  }, [id, getEvent]);

  function _saveDrawingLotsEvent() {
    const drawingLotsEventInput = {
      name,
      productId,
      startTime,
      endTime,
      quota: Number(quota),
      maxNumberOfPeople: Number(maxNumberOfPeople) || null,
      paymentMinuteLimit: Number(paymentMinuteLimit) || null,
      reservationIds: reservationIds.map((item) => {
        return item.value;
      }),
      showQuota,
    };
    if (!Boolean(name)) {
      return Alert.alert("", "名稱未填寫！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(productId)) {
      return Alert.alert("", "商品未選擇！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(startTime)) {
      return Alert.alert("", "開始時間未選擇！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (!Boolean(endTime)) {
      return Alert.alert("", "結束時間未選擇！", [
        { text: "確定", type: "ok" },
      ]);
    }
    if (
      moment(moment(startTime).format("YYYY/MM/DD HH:mm")).valueOf() ===
        moment(moment(endTime).format("YYYY/MM/DD HH:mm")).valueOf() ||
      moment(startTime).isAfter(endTime)
    ) {
      return Alert.alert("", "開始時間不能等於或小於結束時間！", [
        { text: "確定", type: "ok" },
      ]);
    }
    drawingLotsEventInput.startTime = moment(startTime)
      .tz("Asia/Taipei")
      .second(0);
    drawingLotsEventInput.endTime = moment(endTime).tz("Asia/Taipei").second(0);
    if (!Boolean(String(quota))) {
      return Alert.alert("", "名額未填寫！", [{ text: "確定", type: "ok" }]);
    } else if (Boolean(String(quota)) && String(quota) === "0") {
      return Alert.alert("", "名額不可為0！", [{ text: "確定", type: "ok" }]);
    }

    if (quotaError.current) {
      return Alert.alert("", "名額格式錯誤！", [{ text: "確定", type: "ok" }]);
    }

    if (maxNumberOfPeopleError.current) {
      return Alert.alert("", "人數上限格式錯誤！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (paymentMinuteLimitErrorRef.current) {
      return Alert.alert("", "繳費期限格式錯誤！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (memberTierLimitObject) {
      drawingLotsEventInput.memberTierLimit = memberTierLimitObject.value;
    }

    if (Boolean(id)) {
      drawingLotsEventInput.id = id;
    }

    saveDrawingLotsEvent({ variables: { drawingLotsEventInput } });
  }

  const [saveDrawingLotsEvent, { loading: saveDrawingLotsEventLoading }] =
    useMutation(SAVE_DRAWINGLOTSEVENT, {
      onCompleted({ saveDrawingLotsEvent }) {
        if (saveDrawingLotsEvent.success) {
          if (Boolean(id)) {
            activateEvent({ variables: { eventId: id, activated } });
          }
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onPress: () => {
                onChangeSelect();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  function _saveAuctionEvent() {
    const auctionEventInput = {
      name,
      productId,
      startTime,
      endTime,
      bidStartAt: Number(bidStartAt),
      bidIncrement: Number(bidIncrement),
      bidLimitPerBidding: Number(bidLimitPerBidding),
      paymentMinuteLimit: Number(paymentMinuteLimit) || null,
    };

    if (!Boolean(name)) {
      return Alert.alert("", "名稱未填寫！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(productId)) {
      return Alert.alert("", "商品未選擇！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(startTime)) {
      return Alert.alert("", "開始時間未選擇！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (!Boolean(endTime)) {
      return Alert.alert("", "結束時間未選擇！", [
        { text: "確定", type: "ok" },
      ]);
    }
    if (
      moment(moment(startTime).format("YYYY/MM/DD HH:mm")).valueOf() ===
        moment(moment(endTime).format("YYYY/MM/DD HH:mm")).valueOf() ||
      moment(startTime).isAfter(endTime)
    ) {
      return Alert.alert("", "開始時間不能等於或小於結束時間！", [
        { text: "確定", type: "ok" },
      ]);
    }
    auctionEventInput.startTime = moment(startTime).tz("Asia/Taipei").second(0);
    auctionEventInput.endTime = moment(endTime).tz("Asia/Taipei").second(0);
    if (!Boolean(bidStartAt)) {
      return Alert.alert("", "起標價未填寫！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(bidIncrement)) {
      return Alert.alert("", "出價增額未填寫！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (!Boolean(bidLimitPerBidding)) {
      return Alert.alert("", "每次出價最高金額限制未填寫！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (paymentMinuteLimitErrorRef.current) {
      return Alert.alert("", "繳費期限格式錯誤！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (memberTierLimitObject) {
      auctionEventInput.memberTierLimit = memberTierLimitObject.value;
    }

    if (Boolean(id)) {
      auctionEventInput.id = id;
    }
    saveAuctionEvent({ variables: { auctionEventInput } });
  }
  const [saveAuctionEvent, { loading: saveAuctionEventLoading }] = useMutation(
    SAVE_AUCTIONEVENT,
    {
      onCompleted({ saveAuctionEvent }) {
        if (saveAuctionEvent.success) {
          if (Boolean(id)) {
            activateEvent({ variables: { eventId: id, activated } });
          }
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onPress: () => {
                onChangeSelect();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  function _saveRaidEvent() {
    const raidEventInput = {
      name,
      productId,
      startTime,
      endTime,
      monster: "MAO_MAO_ER",
      maxHealthPoint: Number(maxHealthPoint),
      damagePerAttack: Number(damagePerAttack),
      quota: Number(quota),
      showQuota,
      pvpMode,
      paymentMinuteLimit: Number(paymentMinuteLimit) || null,
    };

    if (!Boolean(name)) {
      return Alert.alert("", "名稱未填寫！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(productId)) {
      return Alert.alert("", "商品未選擇！", [{ text: "確定", type: "ok" }]);
    }

    if (!Boolean(maxHealthPoint)) {
      return Alert.alert("", "最大血量未填寫！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (!Boolean(damagePerAttack)) {
      return Alert.notice("每次攻擊血量未填寫！");
    }

    if (!Boolean(startTime)) {
      return Alert.alert("", "開始時間未選擇！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (paymentMinuteLimitErrorRef.current) {
      return Alert.alert("", "繳費期限格式錯誤！", [
        { text: "確定", type: "ok" },
      ]);
    }

    if (memberTierLimitObject) {
      raidEventInput.memberTierLimit = memberTierLimitObject.value;
    }

    if (Boolean(id)) {
      raidEventInput.id = id;
    }
    saveRaidEvent({ variables: { raidEventInput } });
  }
  const [saveRaidEvent, { loading: saveRaidEventLoading }] = useMutation(
    SAVE_RAIDEVENT,
    {
      onCompleted({ saveRaidEvent }) {
        if (saveRaidEvent.success) {
          if (Boolean(id)) {
            activateEvent({ variables: { eventId: id, activated } });
          }
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onPress: () => {
                onChangeSelect();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const [activateEvent, { loading: activateEventLoading }] = useMutation(
    ACTIVATEEVENT,
    {
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const [eventDefaultListFn, { loading: eventDefaultListLoading }] =
    useLazyQuery(EVENT_DEFAULT_LIST, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ eventDefaultList }) {
        if (eventDefaultList) {
          setTimeout(() => {
            const currentWhitelist = eventDefaultList.whitelist.map((item) => ({
              label: item.label,
              value: item.value,
            }));
            setReservationIds((e) => {
              const whitelist = e.concat(currentWhitelist);
              const memberIds = removeDuplicates(whitelist, "value");
              return memberIds;
            });
          }, 0);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });
  const _importWhitelist = useCallback(() => {
    const productIds = getValues("productIds").map((item) => item.value);
    eventDefaultListFn({ variables: { productIds } });
  }, [getValues, eventDefaultListFn]);

  const _handleClick = useCallback(
    async (searchTerm) => {
      const productIds = getValues("productIds").map((item) => item.value);
      return new Promise(async (result) => {
        const data = await _fetchMembers({ searchTerm, productIds });
        result(data);
      });
    },
    [getValues]
  );

  if (eventLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <LoadingModal
          loading={
            saveDrawingLotsEventLoading ||
            saveAuctionEventLoading ||
            activateEventLoading ||
            saveRaidEventLoading ||
            eventDefaultListLoading
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AutocompleteSelect
                label={"選擇活動"}
                items={eventTypeList.filter(
                  (i) => i.value !== "PVP_RAID_EVENT"
                )}
                value={typeof typeObject.value ? typeObject : null}
                onChange={(e, value) => {
                  if (value) {
                    setType(value.value);
                    setTypeObject(value);
                    setProductId(null);
                    setProductObject({});
                    setName("");
                    setStartTime(null);
                    setEndTime(null);
                    setQuota(null);
                    setReservationIds([]);
                    setBidStartAt(null);
                    setBidIncrement(null);
                    setBidLimitPerBidding(null);
                    setMemberTierLimitObject(null);
                    setPaymentMinuteLimit(null);
                    setShowQuota("");
                  } else {
                    setType("DRAWING_LOTS_EVENT");
                    setTypeObject({
                      label: "抽選活動",
                      value: "DRAWING_LOTS_EVENT",
                    });
                    setProductId(null);
                    setProductObject({});
                    setName("");
                    setStartTime(null);
                    setEndTime(null);
                    setQuota(null);
                    setReservationIds([]);
                    setBidStartAt(null);
                    setBidIncrement(null);
                    setBidLimitPerBidding(null);
                    setMemberTierLimitObject(null);
                    setPaymentMinuteLimit(null);
                    setShowQuota("");
                  }
                }}
                disabled={id}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        {(() => {
          switch (type) {
            case "DRAWING_LOTS_EVENT":
              return (
                <Fragment>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          value={name}
                          onChange={(v) => setName(v)}
                          label="名稱"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutocompleteSelect
                          label={"選擇商品"}
                          items={products}
                          value={
                            typeof productObject.value ? productObject : null
                          }
                          onChange={(e, value) => {
                            if (value) {
                              setProductId(value.value);
                              setProductObject(value);
                            } else {
                              setProductId(null);
                              setProductObject({});
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          ampm={false}
                          label={"開始時間"}
                          inputVariant="outlined"
                          format="yyyy年MM月dd日 a hh點mm分"
                          views={["year", "month", "date"]}
                          value={startTime}
                          onChange={(v) => setStartTime(v)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          ampm={false}
                          label={"結束時間"}
                          inputVariant="outlined"
                          format="yyyy年MM月dd日 a hh點mm分"
                          value={endTime}
                          onChange={(v) => setEndTime(v)}
                          fullWidth
                          minDate={moment(startTime)}
                          minDateMessage={"結束時間必須大於開始時間"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={quota}
                          onChange={(v, error) => {
                            setQuota(v);
                            quotaError.current = error;
                          }}
                          label="名額"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={maxNumberOfPeople}
                          onChange={(v, error) => {
                            setMaxNumberOfPeople(v);
                            maxNumberOfPeopleError.current = error;
                          }}
                          label="人數上限"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          value={showQuota}
                          onChange={setShowQuota}
                          label="前台顯示抽選名額"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutocompleteSelect
                          label={"選擇會員級別參加限制"}
                          items={[
                            { label: "小馬力", value: "HORSE_POWER" },
                            { label: "超級馬力", value: "SUPER_HORSE_POWER" },
                            {
                              label: "藏家馬力",
                              value: "COLLECTOR_HORSE_POWER",
                            },
                          ]}
                          value={memberTierLimitObject}
                          onChange={(e, value) =>
                            setMemberTierLimitObject(value)
                          }
                          fullWidth
                        />
                      </Grid>
                      {id && (
                        <Grid item xs={12} sm={6}>
                          <AutocompleteSelect
                            items={activatedArray}
                            value={activatedObject}
                            onChange={(e, v) => {
                              if (v) {
                                setActivated(v.value);
                                setActivatedObject(v);
                              } else {
                                setActivated(true);
                                setActivatedObject({
                                  label: "上架",
                                  value: true,
                                });
                              }
                            }}
                            label="狀態"
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={paymentMinuteLimit}
                          onChange={(v, error) => {
                            setPaymentMinuteLimit(v);
                            paymentMinuteLimitErrorRef.current = error;
                          }}
                          label="繳費期限(分鐘)"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container spacing={1} direction="row">
                      <Grid container item>
                        <FormLabel>名單</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div style={{ width: "100%" }}>
                          <FormProvider {...form}>
                            <CheckboxesTags
                              name="productIds"
                              noOptionsText="找不到商品"
                              textFieldLabel="購買特定商品的會員"
                              textFieldPlaceholder="選擇購買特定商品的會員"
                              options={emptyArray(
                                productsAllData?.products.products
                              )}
                              onChange={(_, value) => {
                                setValue("productIds", value);
                              }}
                            />
                          </FormProvider>
                        </div>
                      </Grid>
                      <Grid container item direction="row" spacing={1}>
                        {reservationIds.map((item, index) => {
                          return (
                            <Grid item>
                              <Chip
                                label={item.label}
                                color="primary"
                                onDelete={() => {
                                  setReservationIds((e) =>
                                    e.filter((i, j) => j !== index)
                                  );
                                }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid container item spacing={1}>
                        <Grid item xs={12} sm={"auto"}>
                          <Box display="flex">
                            <CustomDropdown
                              textInputProps={{ placeholder: "搜尋會員" }}
                              handleClick={_handleClick}
                              value={reservationIds}
                              onChange={(value) => {
                                const hasOption = reservationIds.find(
                                  (item) => item.value === value.value
                                );
                                if (Boolean(hasOption)) {
                                  return null;
                                } else {
                                  setReservationIds((e) => [...e, value]);
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onPress={_importWhitelist}
                          >
                            一鍵匯入名單
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Fragment>
              );
            case "AUCTION_EVENT":
              return (
                <Fragment>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          value={name}
                          onChange={(v) => setName(v)}
                          label="名稱"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutocompleteSelect
                          label={"選擇商品"}
                          items={products}
                          value={
                            typeof productObject.value ? productObject : null
                          }
                          onChange={(e, value) => {
                            if (value) {
                              setProductId(value.value);
                              setProductObject(value);
                            } else {
                              setProductId(null);
                              setProductObject({});
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          ampm={false}
                          label={"開始時間"}
                          inputVariant="outlined"
                          format="yyyy年MM月dd日 a hh點mm分"
                          views={["year", "month", "date"]}
                          value={startTime}
                          onChange={(v) => setStartTime(v)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          ampm={false}
                          label={"結束時間"}
                          inputVariant="outlined"
                          format="yyyy年MM月dd日 a hh點mm分"
                          value={endTime}
                          onChange={(v) => setEndTime(v)}
                          fullWidth
                          minDate={moment(startTime)}
                          minDateMessage={"結束時間必須大於開始時間"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={bidStartAt}
                          onChange={(v, error) => {
                            setBidStartAt(v);
                          }}
                          label="起標價"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={bidIncrement}
                          onChange={(v, error) => {
                            setBidIncrement(v);
                          }}
                          label="出價增額"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={bidLimitPerBidding}
                          onChange={(v, error) => {
                            setBidLimitPerBidding(v);
                          }}
                          label="每次出價最高金額限制"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutocompleteSelect
                          label={"選擇會員級別參加限制"}
                          items={[
                            { label: "小馬力", value: "HORSE_POWER" },
                            { label: "超級馬力", value: "SUPER_HORSE_POWER" },
                            {
                              label: "藏家馬力",
                              value: "COLLECTOR_HORSE_POWER",
                            },
                          ]}
                          value={memberTierLimitObject}
                          onChange={(e, value) =>
                            setMemberTierLimitObject(value)
                          }
                          fullWidth
                        />
                      </Grid>
                      {id && (
                        <Grid item xs={12} sm={6}>
                          <AutocompleteSelect
                            items={activatedArray}
                            value={activatedObject}
                            onChange={(e, v) => {
                              if (v) {
                                setActivated(v.value);
                                setActivatedObject(v);
                              } else {
                                setActivated(true);
                                setActivatedObject({
                                  label: "上架",
                                  value: true,
                                });
                              }
                            }}
                            label="狀態"
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={paymentMinuteLimit}
                          onChange={(v, error) => {
                            setPaymentMinuteLimit(v);
                            paymentMinuteLimitErrorRef.current = error;
                          }}
                          label="繳費期限(分鐘)"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Fragment>
              );
            case "RAID_EVENT":
              return (
                <Fragment>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid container item xs={12} sm={6}>
                        <Grid item xs={12} sm={9}>
                          <TextInput
                            value={name}
                            onChange={(v) => setName(v)}
                            label="名稱"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            minWidth={"100px"}
                            alignItems={"center"}
                          >
                            <Checkbox
                              checked={pvpMode}
                              onChange={(e) => setPvpMode(e.target.checked)}
                            />
                            <Typography color="primary">對戰模式</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutocompleteSelect
                          label={"選擇商品"}
                          items={products}
                          value={
                            typeof productObject.value ? productObject : null
                          }
                          onChange={(e, value) => {
                            if (value) {
                              setProductId(value.value);
                              setProductObject(value);
                            } else {
                              setProductId(null);
                              setProductObject({});
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={maxHealthPoint}
                          onChange={(v, error) => {
                            setMaxHealthPoint(v);
                          }}
                          label="最大血量"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          ampm={false}
                          label={"開始時間"}
                          inputVariant="outlined"
                          format="yyyy年MM月dd日 a hh點mm分"
                          views={["year", "month", "date"]}
                          value={startTime}
                          onChange={(v) => {
                            setStartTime(
                              moment(v).tz("Asia/Taipei").seconds(0).toDate()
                            );
                            setEndTime(
                              moment(v)
                                .tz("Asia/Taipei")
                                .seconds(0)
                                .add(30, "seconds")
                                .toDate()
                            );
                          }}
                          minDate={new Date()}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={damagePerAttack}
                          onChange={(v, error) => {
                            setDamagePerAttack(v);
                          }}
                          label="每次攻擊血量"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={quota}
                          onChange={(v, error) => {
                            setQuota(v);
                            quotaError.current = error;
                          }}
                          label="抽選名額"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          value={showQuota}
                          onChange={setShowQuota}
                          label="前台顯示抽選名額"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutocompleteSelect
                          label={"選擇會員級別參加限制"}
                          items={[
                            { label: "小馬力", value: "HORSE_POWER" },
                            { label: "超級馬力", value: "SUPER_HORSE_POWER" },
                            {
                              label: "藏家馬力",
                              value: "COLLECTOR_HORSE_POWER",
                            },
                          ]}
                          value={memberTierLimitObject}
                          onChange={(e, value) =>
                            setMemberTierLimitObject(value)
                          }
                          fullWidth
                        />
                      </Grid>
                      {id && (
                        <Grid item xs={12} sm={6}>
                          <AutocompleteSelect
                            items={activatedArray}
                            value={activatedObject}
                            onChange={(e, v) => {
                              if (v) {
                                setActivated(v.value);
                                setActivatedObject(v);
                              } else {
                                setActivated(true);
                                setActivatedObject({
                                  label: "上架",
                                  value: true,
                                });
                              }
                            }}
                            label="狀態"
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          value={paymentMinuteLimit}
                          onChange={(v, error) => {
                            setPaymentMinuteLimit(v);
                            paymentMinuteLimitErrorRef.current = error;
                          }}
                          label="繳費期限(分鐘)"
                          fullWidth
                          thousandSeparator
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Fragment>
              );
            default:
              return null;
          }
        })()}
        <CardActions>
          <Grid container item justify="flex-end">
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onPress={() => {
                  switch (type) {
                    case "DRAWING_LOTS_EVENT":
                      return _saveDrawingLotsEvent();
                    case "AUCTION_EVENT":
                      return _saveAuctionEvent();
                    case "RAID_EVENT":
                      return _saveRaidEvent();
                    default:
                      return null;
                  }
                }}
              >
                儲存
              </Button>
            </CardActions>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

/** - 抓取會員 */
const _fetchMembers = async (props) => {
  const { searchTerm, productIds } = props ?? {};
  return new Promise(async (result, reject) => {
    try {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: GET_MEMBERS,
        variables: {
          productIds,
          searchTerm,
          hidden: false,
        },
      });
      if (data?.members) {
        result(data.members?.members ?? []);
      }
    } catch (error) {
      console.log("MembersError", error);
      reject(error);
    }
  });
};
