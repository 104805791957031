import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  memo,
  useCallback,
} from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Chip,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
// apollo
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

// component
import { useAlert } from "../../component/Alert";
import Button from "../../component/Button";
import { AutocompleteFocusSelect } from "../../component/Form";
import LoadingFloating from "../../component/LoadingFloating";
// utils
import emptyArray from "../../utils/emptyArray";

// SECTION apollo
// NOTE 會員列表
const GET_MEMBERS = gql`
  query members($searchTerm: String, $hidden: Boolean) {
    members(searchTerm: $searchTerm, hidden: $hidden) {
      members {
        id
        fullName
      }
    }
  }
`;
// NOTE 活動
const GET_EVENT = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      ... on DrawingLotsEvent {
        blocklist {
          id
          fullName
        }
      }
      ... on RaidEvent {
        blocklist {
          id
          fullName
        }
      }
    }
  }
`;
// NOTE 儲存抽選黑名單
const SAVE_BLOCKLIST = gql`
  mutation saveBlocklist($eventId: Int!, $memberIds: [Int!]!) {
    saveBlocklist(eventId: $eventId, memberIds: $memberIds) {
      success
      message
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
function SaveBlocklistFloaingLayer({ _onClose, eventId }, ref) {
  const { notice } = useAlert();
  // SECTION style
  const useStyles = makeStyles({
    memberIdsBox: {
      display: "flex",
      flexDirection: "row",
      minHeight: 40,
      border: "1px solid #C0C0C0",
      borderRadius: "4px",
      alignItems: "center",
      flexWrap: "wrap",
      "& > div": {
        padding: 5,
      },
    },
    text: {
      flexGrow: 1,
    },
  });
  const classes = useStyles();
  // !SECTION

  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermObject, setSearchTermObject] = useState({});

  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      memberIds: [],
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      open: (e) => {
        const newMemberIds = emptyArray(e).map((item) => ({
          label: item.fullName,
          value: item.id,
        }));
        setValue("memberIds", newMemberIds);
        setOpen(true);
      },
    }),
    [setValue]
  );

  const onClose = useCallback(
    (value) => {
      reset();
      setOpen(false);
      _onClose?.(value);
    },
    [reset, _onClose]
  );

  const handleInputChange = useCallback((_, newInputValue) => {
    setSearchTerm(newInputValue);
    setSearchTermObject({ label: newInputValue, value: "null" });
  }, []);

  const [getMembers, { loading: membersLoading }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ members }) {
      if (members) {
        setTimeout(() => {
          const newMembers = members.members.map((item) => ({
            label: item.fullName,
            value: item.id,
          }));
          setOpenSelect(() => {
            setMembers(newMembers);
            return true;
          });
        }, 0);
      }
    },
  });

  const [getEvent, { loading: eventLoading }] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          const newMemberIds = event.blocklist.map((item) => ({
            label: item.fullName,
            value: item.id,
          }));
          setValue("memberIds", newMemberIds);
        }, 0);
      }
    },
    onError(error) {
      notice(error.message.replace("GraphQL error: ", ""));
    },
  });

  useEffect(() => {
    if (eventId) {
      getEvent({ variables: { id: eventId } });
    }
  }, [eventId, getEvent]);

  const [saveBlocklist, { loading: saveBlocklistLoading }] = useMutation(
    SAVE_BLOCKLIST,
    {
      onCompleted({ saveBlocklist }) {
        if (saveBlocklist.success) {
          onClose("mutation");
          return notice("儲存成功！");
        } else if (saveBlocklist.message) {
          return notice(saveBlocklist.message);
        }
      },
      onError(error) {
        notice(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  const _saveBlocklist = useCallback(
    ({ memberIds }) => {
      const newMemberIds = memberIds.map((item) => item.value);
      saveBlocklist({
        variables: { eventId, memberIds: newMemberIds },
      });
    },
    [saveBlocklist, eventId]
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableScrollLock
      open={open}
      onClose={onClose}
    >
      <LoadingFloating loading={saveBlocklistLoading} />
      <DialogTitle>第二頁</DialogTitle>
      <DialogContent
        style={{
          height: "190px",
          display: "flex",
          justifyContent: "center",
          overflowY: 0,
        }}
      >
        {eventLoading ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="memberIds"
                rules={{
                  required: "必填選項",
                }}
                render={({
                  field: { value: memberIds, onChange },
                  fieldState: { error },
                }) => (
                  <Grid container item direction="column" spacing={1}>
                    <Grid item>
                      <InputLabel style={error && { color: "red" }}>
                        名單
                      </InputLabel>
                    </Grid>
                    <Grid item>
                      <Box
                        item
                        className={classes.memberIdsBox}
                        style={error && { borderColor: "red" }}
                      >
                        {memberIds.map((item, index) => {
                          return (
                            <Grid item>
                              <Chip
                                label={item.label}
                                color="primary"
                                onDelete={() => {
                                  const deleteMemberIds = memberIds.filter(
                                    (i, j) => j !== index
                                  );
                                  onChange(deleteMemberIds);
                                }}
                              />
                            </Grid>
                          );
                        })}
                      </Box>
                    </Grid>
                    {error && error.message && (
                      <Grid item>
                        <FormHelperText style={{ color: "red" }}>
                          &ensp;&ensp;{error.message}
                        </FormHelperText>
                      </Grid>
                    )}
                    <Grid container item>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex">
                          <AutocompleteFocusSelect
                            open={openSelect}
                            label={"選擇會員"}
                            items={members}
                            onInputChange={handleInputChange}
                            loading={membersLoading}
                            value={
                              searchTermObject.value === "null"
                                ? searchTermObject
                                : null
                            }
                            onChange={(e, value) => {
                              if (value) {
                                const hasOption = memberIds.find(
                                  (item) => item.value === value.value
                                );
                                if (Boolean(hasOption)) {
                                  return null;
                                } else {
                                  const newMemberIds = [...memberIds, value];
                                  onChange(newMemberIds);
                                }
                              } else {
                                setSearchTerm(null);
                                setSearchTermObject({});
                                setOpenSelect(false);
                              }
                            }}
                            renderOption={(option) => {
                              const hasOption = memberIds.find(
                                (item) => item.value === option.value
                              );
                              return (
                                <React.Fragment>
                                  <div className={classes.text}>
                                    {option.label}
                                  </div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={Boolean(hasOption)}
                                  >
                                    {hasOption ? "已加入" : "未加入"}
                                  </Button>
                                </React.Fragment>
                              );
                            }}
                            fullWidth
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onPress={() => {
                              if (openSelect) {
                                setSearchTerm(null);
                                setSearchTermObject({});
                                setOpenSelect(false);
                              } else {
                                getMembers({
                                  variables: { searchTerm, hidden: false },
                                });
                              }
                            }}
                            disabled={!Boolean(searchTerm) || membersLoading}
                          >
                            {openSelect ? "關閉" : "搜尋"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          {!eventLoading && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onPress={handleSubmit(_saveBlocklist)}
              >
                確定
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" color="primary" onPress={onClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default memo(forwardRef(SaveBlocklistFloaingLayer));
