import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  useRef,
  useCallback,
} from "react";
// material-ui
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  useTheme,
  Typography,
  Box,
  Button,
  Card,
  useMediaQuery,
  CardActionArea,
  CircularProgress,
  colors,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";
// react-router-dom
import { generatePath, useHistory, useParams } from "react-router-dom";
// react-helmet
import { Helmet } from "react-helmet";
// react-swipeable-views
import SwipeableViews from "react-swipeable-views";
// apollo
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// moment
import moment from "moment";
// react-number-format
import { NumericFormat } from "react-number-format";
// react-redux
import { useSelector } from "react-redux";

// component
import SquareBox from "../component/SquareBox";
import { useAlert } from "../component/Alert";
import {
  splitArray,
  getMemberTier,
  checkMemberTierLimit,
} from "../component/utils";
import DateCountdown from "../component/DateCountdown";
import LoadingFloating from "../component/LoadingFloating";
import EventConsentFloatingLayer from "../component/EventConsentFloatingLayer";
import {
  thousandsSeparator,
  stringHiddenInTheMiddle,
} from "../component/utils";
import Table from "../component/Table";
import TermsDialog from "../component/TermsDialog";
// utils
import judgeEventType from "../utils/judgeEventType";
// zustand
import useTokenStore from "../zustand/useTokenStore";
import useMemberIdStore from "../zustand/useMemberIdStore";

const GET_EVENT = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      type
      name
      product {
        id
        images {
          filename
          mimetype
          encoding
          location
        }
        name
        size
        material
        price
        details {
          type
          body
        }
      }
      startTime
      endTime
      memberTierLimit
      ... on DrawingLotsEvent {
        quota
        showQuota
        participated
        participants {
          id
          fullName
          mobile
        }
        drew
        results {
          id
          fullName
          mobile
        }
        # "參加人數上限"
        maxNumberOfPeople
      }
      ... on AuctionEvent {
        winningBidder {
          id
        }
      }
      ... on RaidEvent {
        joined
        status
        players {
          player {
            fullName
          }
          score
        }
      }
    }
  }
`;

//參加抽選
const JOIN_DRAWINGLOTSEVENT = gql`
  mutation joinDrawingLotsEvent($id: Int!) {
    joinDrawingLotsEvent(id: $id) {
      success
      message
    }
  }
`;

//抽選提早抽出Query
const GET_EVENT_DREW = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      ... on DrawingLotsEvent {
        # "已達人數上限"
        reachedMaxNumberOfPeople
        drew
        results {
          id
        }
      }
    }
  }
`;

//競標Query僅目前出價金額
const GET_EVENT_NEWESTBID = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      ... on AuctionEvent {
        newestBid
      }
    }
  }
`;

//競標Query
const GET_EVENT_BID = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      startTime
      endTime
      ... on AuctionEvent {
        bidIncrement
        bidLimitPerBidding
        newestBid
        newestBidder {
          id
        }
        numberOfBidder
        winningBidder {
          id
        }
      }
    }
  }
`;

//競標出價紀錄Query
const GET_EVENT_BIDDINGS = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      ... on AuctionEvent {
        biddings {
          id
          bidder {
            id
            fullName
            mobile
          }
          bid
        }
      }
    }
  }
`;

//團體戰Query
const GET_EVENT_RAID = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      ... on RaidEvent {
        monster
        maxHealthPoint
        quota
        showQuota
        enabled
        joined
      }
    }
  }
`;

//競標出價
const ADD_BID = gql`
  mutation addBid($eventId: Int!, $bid: Float!) {
    addBid(eventId: $eventId, bid: $bid) {
      success
      message
    }
  }
`;

//參加團體戰
const JOIN_RAIDEVENT = gql`
  mutation joinRaidEvent($eventId: Int!) {
    joinRaidEvent(eventId: $eventId) {
      success
      message
    }
  }
`;

//團體戰怪物血量
const GET_RAIDBIGSCREEN = gql`
  query raidBigScreen($eventId: Int!) {
    raidBigScreen(eventId: $eventId) {
      status
      currentHealthPoint
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  infoText: {
    color: theme.palette.text.hint,
  },
  tag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `0 ${theme.spacing(1)}px`,
    height: 32,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    borderRadius: 0,
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

export default function DrawingLotsEventPage() {
  const Alert = useAlert();
  const { id } = useParams();
  const [newImages, setNewImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [select, setSelect] = useState(0);
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.host.search(".s3-") === -1
    ) {
      window.fbq("track", "ViewContent");
    }
  }, []);

  const [getEvent, { data, loading: eventLoading }] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          setNewImages(event.product.images);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (id) {
      getEvent({ variables: { id: Number(id) } });
    }
  }, [id, getEvent]);

  function switchArea() {
    if (eventLoading) {
      return (
        <Box
          display="flex"
          height={`calc(100vh - 64px - ${theme.spacing(3)}px)`}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    } else {
      if (data && data.event) {
        return (
          <>
            <Grid container>
              <Helmet>
                <title>{data.event.name}｜路遙圓創</title>
              </Helmet>
              <Grid
                container
                item
                direction="column"
                justify="space-between"
                xs={12}
                sm={6}
              >
                <div>
                  <Box display="flex" padding={`${theme.spacing(1)}px 0`}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box className={classes.tag}>
                          <Typography variant="h6">
                            {judgeEventType(data.event)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography variant="h5" className={classes.title}>
                    {data.event.name}
                  </Typography>
                  <Divider className={classes.divider} />
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  margin={`${theme.spacing(4)}px 0`}
                >
                  <Box>
                    <Typography className={classes.infoText}>
                      尺寸&ensp;|&ensp;{data.event.product.size}
                    </Typography>
                    <Typography className={classes.infoText}>
                      材質&ensp;|&ensp;{data.event.product.material}
                    </Typography>
                    {data.event.type === "DRAWING_LOTS_EVENT" &&
                      data.event.showQuota && (
                        <Typography className={classes.infoText}>
                          名額&ensp;|&ensp;
                          {data.event.showQuota ?? ""}
                        </Typography>
                      )}
                  </Box>
                  <PriceBrand
                    id={id}
                    type={data.event.type}
                    price={data.event.product.price}
                  />
                </Box>
                <Rankingboard
                  type={data.event.type}
                  data={data.event.players}
                />
                <Box paddingBottom={1} paddingTop={1}>
                  {(() => {
                    switch (data.event.type) {
                      case "DRAWING_LOTS_EVENT":
                        return (
                          <LotteryButton
                            id={data.event.id}
                            participated={data.event.participated}
                            startTime={data.event.startTime}
                            endTime={data.event.endTime}
                            drew={data.event.drew}
                            results={data.event.results}
                            price={data.event.product.price}
                            participants={
                              data.event.participants
                                ? data.event.participants
                                : null
                            }
                            eventMemberTierLimit={data.event.memberTierLimit}
                            maxNumberOfPeople={data.event.maxNumberOfPeople}
                            onRefetch={() => {
                              getEvent({ variables: { id: Number(id) } });
                            }}
                          />
                        );
                      case "AUCTION_EVENT":
                        return (
                          <AuctionEventLotteryButton
                            id={data.event.id}
                            startTime={data.event.startTime}
                            endTime={data.event.endTime}
                            winningBidder={data.event.winningBidder}
                            eventMemberTierLimit={data.event.memberTierLimit}
                          />
                        );
                      case "RAID_EVENT":
                      case "PVP_RAID_EVENT":
                        return (
                          <RaidEventLotteryButton
                            id={data.event.id}
                            startTime={data.event.startTime}
                            endTime={data.event.endTime}
                            joined={data.event.joined}
                            status={data.event.status}
                            eventMemberTierLimit={data.event.memberTierLimit}
                            type={data.event.type}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </Box>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Box padding={1} display="flex" flex={1}>
                  <ProductImages
                    index={imageIndex}
                    onChangeIndex={setImageIndex}
                    data={splitArray(
                      newImages,
                      (() => {
                        if (isMobile) {
                          return 2;
                        } else if (isTable) {
                          return 3;
                        } else {
                          return 4;
                        }
                      })()
                    )}
                    sizeValue={[isTable, isMobile]}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Tabs value={select} onChange={(e, n) => setSelect(n)}>
                <Tab label="商品介紹" />
                {/* {data.event.type === "DRAWING_LOTS_EVENT" &&
                  data.event.results?.length !== 0 && <Tab label="得獎名單" />} */}
                {data.event.type === "AUCTION_EVENT" && (
                  <Tab label="出價紀錄" />
                )}
              </Tabs>
            </Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(1)}px 0` }}
            />
            {Boolean(select === 0) && (
              <Grid container direction="column" spacing={1}>
                {data.event.product.details.map((item, index) => (
                  <Grid container item key={index}>
                    <DetailArea data={item} />
                  </Grid>
                ))}
              </Grid>
            )}
            {Boolean(select === 1) && (
              <Box>
                {data.event.type === "AUCTION_EVENT" && (
                  <EventBiddings id={data.event.id} />
                )}
              </Box>
            )}
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(5)}px 0` }}
            />
          </>
        );
      } else {
        return null;
      }
    }
  }
  return (
    <Container maxWidth="md" className={classes.container}>
      {switchArea()}
    </Container>
  );
}
function LotteryButton({
  id,
  participated,
  startTime,
  endTime,
  drew,
  results,
  price,
  participants,
  eventMemberTierLimit,
  maxNumberOfPeople,
  onRefetch = () => {},
}) {
  const Alert = useAlert();
  const useStyles = makeStyles({
    button: {
      borderRadius: 0,
      width: "100%",
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const memberTierLimit = useSelector((state) => state.member.memberTierLimit);
  const [eventStartBefore, setEventStartBefore] = useState(false);
  const [eventStart, setEventStart] = useState(false);
  const [joined, setJoined] = useState(false);
  const [open, setOpen] = useState(false);
  const drewRefreshStopRef = useRef(false);
  const [winningTheLottery, setWinningTheLottery] = useState(false);
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const memberId = useMemberIdStore(useCallback((state) => state.memberId, []));
  const isFlashSale = Boolean(maxNumberOfPeople);
  const changeName = isFlashSale ? "限額抽選" : "抽選";

  const [getEventDrew, { data: eventDrewData }] = useLazyQuery(GET_EVENT_DREW, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          if (event.drew && !drewRefreshStopRef.current) {
            const has = event.results.findIndex((item) => item.id === memberId);
            if (has > -1) {
              setWinningTheLottery(true);
            }
            setEventStart(false);
            drewRefreshStopRef.current = true;
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getEventDrew({ variables: { id: Number(id) } });
    }
  }, [id, getEventDrew]);

  const hasResult = useMemo(() => {
    if (Boolean(results[0])) {
      return Boolean(results.find((item) => item.id === memberId));
    } else {
      return false;
    }
  }, [results, memberId]);

  useEffect(() => {
    if (typeof participated === "boolean") {
      setJoined(participated);
    }
  }, [participated]);

  useEffect(() => {
    if (drew) {
      setEventStartBefore(false);
      return setEventStart(false);
    }
    if (!moment(startTime).isBefore()) {
      setEventStartBefore(true);
    } else if (!moment(endTime).isBefore()) {
      setEventStart(true);
    }
  }, [startTime, endTime, drew]);

  function _joinDrawingLotsEvent() {
    if (memberToken) {
      if (checkMemberTierLimit(memberTierLimit, eventMemberTierLimit))
        return setTimeout(() => {
          Alert.notice(
            `會員等級必須達到「${getMemberTier(
              eventMemberTierLimit
            )}」才有參加資格！`
          );
        }, 0);
      else {
        setOpen(true);
      }
    } else {
      return Alert.alert("通知", `尚未登入，請先登入才可參加${changeName}！`, [
        {
          text: "登入",
          onPress: () => history.push("/login", { goBackPage: true }),
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    }
  }

  const [joinDrawingLotsEvent, { loading: joinDrawingLotsEventLoading }] =
    useMutation(JOIN_DRAWINGLOTSEVENT, {
      onCompleted({ joinDrawingLotsEvent }) {
        if (joinDrawingLotsEvent.success) {
          setJoined(true);
          if (price === 0) {
            Alert.notice(
              `感謝您參與本次${changeName}，活動截止後將以簡訊通知中選者，收到通知者請至『會員中心』>『待出貨商品』確認，如有任何疑問請洽詢粉專，謝謝。\n(未中選者不另行通知)`
            );
          } else {
            Alert.notice(
              `感謝您參與本次${changeName}，活動截止後將以簡訊通知中選者，收到通知者請至『會員中心』>『待付款訂單』確認商品並於時間內完成繳費。\n(未中選者不另行通知)`
            );
          }
          onRefetch();
        } else if (joinDrawingLotsEvent.message) {
          if (joinDrawingLotsEvent.message.search("登入") > -1) {
            return Alert.alert("", joinDrawingLotsEvent.message, [
              {
                text: "回首頁",
                onPress: () => history.replace("/"),
                type: "ok",
              },
              {
                text: "登入",
                onPress: () => history.replace("/login"),
                type: "cancel",
              },
            ]);
          } else {
            return Alert.notice(joinDrawingLotsEvent.message);
          }
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  return (
    <Grid container spacing={1}>
      <LoadingFloating loading={joinDrawingLotsEventLoading} />
      <EventConsentFloatingLayer
        open={open}
        isFlashSale={isFlashSale}
        onChangeFloatingwindowClose={(value) => {
          setOpen(false);
          if (typeof value === "boolean" && value) {
            joinDrawingLotsEvent({ variables: { id } });
          }
        }}
      />
      {(hasResult || winningTheLottery) && (
        <Grid container item>
          <Typography style={{ color: colors.red["700"] }}>
            {`恭喜您成功${isFlashSale ? "獲得" : "抽選"}此商品，`}
          </Typography>
          <Typography style={{ color: colors.red["700"] }}>
            {"該商品已新贈至您的會員中心＞待付款訂單，"}
          </Typography>
          <Typography style={{ color: colors.red["700"] }}>
            {"請於期限內完成付款，否則將會失去購買權利並停權帳號，"}
          </Typography>
          <Typography style={{ color: colors.red["700"] }}>
            {"如有任何問題可私訊路遙圓創粉絲專頁客服詢問。"}
          </Typography>
        </Grid>
      )}
      {(() => {
        if (eventStartBefore) {
          // NOTE 活動開始前
          return (
            <Grid item container justify="center">
              <DateCountdown
                start={moment(startTime)}
                startLabel="活動開始倒數"
                onEnd={() =>
                  setEventStartBefore(() => {
                    setEventStart(true);
                    return false;
                  })
                }
              />
            </Grid>
          );
        } else {
          if (eventStart) {
            // NOTE 活動開始
            return (
              <Fragment>
                <Grid item container>
                  <Typography style={{ color: "#9e9e9e" }}>
                    目前參加人數：{participants.length}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    disabled={
                      joined ||
                      Boolean(eventDrewData?.event?.reachedMaxNumberOfPeople)
                    }
                    onClick={() => _joinDrawingLotsEvent()}
                  >
                    <Typography variant="h6">
                      {joined
                        ? `您已參加${changeName}`
                        : Boolean(
                            eventDrewData?.event?.reachedMaxNumberOfPeople
                          )
                        ? "已達人數上限"
                        : `參加${changeName}`}
                    </Typography>
                  </Button>
                </Grid>
                {moment(endTime).isAfter() && (
                  <Grid item container justify="center">
                    <DateCountdown
                      end={moment(endTime)}
                      endLabel={`${changeName}倒數`}
                      onEnd={() => setEventStart(false)}
                    />
                  </Grid>
                )}
              </Fragment>
            );
          } else {
            return (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    height: 44,
                    backgroundColor: colors.red["700"],
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {hasResult || winningTheLottery
                      ? `您已被${isFlashSale ? "選" : "抽選"}到`
                      : `${changeName}結束`}
                  </Typography>
                </Box>
              </Grid>
            );
          }
        }
      })()}
    </Grid>
  );
}
function AuctionEventLotteryButton({
  id,
  startTime,
  endTime,
  winningBidder,
  eventMemberTierLimit,
}) {
  const Alert = useAlert();
  const useStyles = makeStyles({
    button: {
      borderRadius: 0,
      width: "100%",
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const memberTierLimit = useSelector((state) => state.member.memberTierLimit);
  const [eventStartBefore, setEventStartBefore] = useState(false);
  const [eventStart, setEventStart] = useState(false);
  const [bid, setBid] = useState("");
  const auctionRefreshStopRef = useRef(false);
  const [winningTheAuction, setWinningTheAuction] = useState(false);
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const memberId = useMemberIdStore(useCallback((state) => state.memberId, []));

  useEffect(() => {
    if (!moment(startTime).isBefore()) {
      setEventStartBefore(true);
    } else if (!moment(endTime).isBefore()) {
      setEventStart(true);
    }
  }, [startTime, endTime]);

  const [getEvent, { data }] = useLazyQuery(GET_EVENT_BID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          if (moment(event.startTime).isBefore() && eventStartBefore) {
            setEventStartBefore(false);
          } else if (moment(event.endTime).isBefore() && eventStart) {
            setEventStart(false);
          }
          if (event.winningBidder && !auctionRefreshStopRef.current) {
            if (event.winningBidder.id === memberId) {
              setWinningTheAuction(true);
            }
            auctionRefreshStopRef.current = true;
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getEvent({ variables: { id: Number(id) } });
    }
  }, [id, getEvent]);
  function removeThousandSeparator(number) {
    return String(number).replace(/,/g, "");
  }
  function _addBid(memberBid) {
    if (memberToken) {
      if (checkMemberTierLimit(memberTierLimit, eventMemberTierLimit))
        return setTimeout(() => {
          Alert.notice(
            `會員等級必須達到「${getMemberTier(
              eventMemberTierLimit
            )}」才有參加資格！`
          );
        }, 0);
      else
        addBid({
          variables: {
            eventId: id,
            bid: Boolean(memberBid)
              ? memberBid
              : Number(bid.replace(/[,]/g, "")),
          },
        });
    } else {
      setTimeout(() => {
        return Alert.alert("通知", "尚未登入，請先登入才可參加競標！", [
          {
            text: "登入",
            onPress: () => history.push("/login", { goBackPage: true }),
            type: "ok",
          },
          {
            text: "取消",
            onPress: () => setBid(""),
            type: "cancel",
          },
        ]);
      }, 0);
    }
  }
  const [addBid, { loading: addBidLoading }] = useMutation(ADD_BID, {
    onCompleted({ addBid }) {
      if (addBid.success) {
        return setBid("");
      } else if (addBid.message) {
        if (addBid.message.search("登入") > -1) {
          return Alert.alert("", addBid.message, [
            {
              text: "回首頁",
              onPress: () => history.replace("/"),
              type: "ok",
            },
            {
              text: "登入",
              onPress: () => history.replace("/login"),
              type: "cancel",
            },
          ]);
        } else {
          return Alert.notice(addBid.message);
        }
      } else {
        return Alert.notice("參加競標失敗，請重新嘗試！");
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  return (
    <Grid container spacing={1}>
      <LoadingFloating loading={addBidLoading} />
      {((winningBidder && winningBidder.id === memberId) ||
        winningTheAuction) && (
        <Grid container item>
          <Typography style={{ color: colors.red["700"] }}>
            {"恭喜您成功競標此商品，"}
          </Typography>
          <Typography style={{ color: colors.red["700"] }}>
            {"該商品已新贈至您的會員中心＞待付款訂單，"}
          </Typography>
          <Typography style={{ color: colors.red["700"] }}>
            {"請於期限內完成付款，否則將會失去購買權利並停權帳號，"}
          </Typography>
          <Typography style={{ color: colors.red["700"] }}>
            {"如有任何問題可私訊路遙圓創粉絲專頁客服詢問。"}
          </Typography>
        </Grid>
      )}
      {(() => {
        if (eventStartBefore) {
          // NOTE 活動開始前
          return (
            <Grid item container justify="center">
              <DateCountdown
                start={moment(startTime)}
                startLabel="活動開始倒數"
                onEnd={() =>
                  setEventStartBefore(() => {
                    setEventStart(true);
                    return false;
                  })
                }
              />
            </Grid>
          );
        } else {
          if (eventStart) {
            // NOTE 活動開始
            return (
              <Fragment>
                <Grid item container>
                  <Typography style={{ color: "#9e9e9e" }}>
                    目前參加競標人數：{data?.event.numberOfBidder}
                  </Typography>
                </Grid>
                <Grid item container justify="space-evenly">
                  <Typography style={{ color: "#9e9e9e" }}>
                    每次出價增額需：
                    {thousandsSeparator(data?.event.bidIncrement)}
                  </Typography>
                  <Typography style={{ color: "#9e9e9e" }}>
                    目前最高可出價金額：
                    {thousandsSeparator(
                      data?.event.newestBid + data?.event.bidLimitPerBidding
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="請輸入競標價格"
                    value={bid}
                    onChange={(event) => {
                      const newE = event.target.value;
                      setBid(removeThousandSeparator(newE));
                    }}
                    onBlur={(event) => {
                      const newValue = event.target.value.replace(/[,]/g, "");
                      if (!Boolean(event.target.value)) {
                        return;
                      }
                      if (
                        Number(newValue) <
                        data?.event.newestBid + data?.event.bidIncrement
                      ) {
                        Alert.alert(
                          "",
                          `您好，您的出價未符合競標規則，請參考網站＞常見問題＞競標問題進行查詢。\n如欲繼續競標，您的金額將會修正為下方金額。\n調整金額為：${
                            data?.event.newestBid + data?.event.bidIncrement
                          }`,
                          [
                            {
                              text: "確認",
                              onPress: () =>
                                _addBid(
                                  data?.event.newestBid +
                                    data?.event.bidIncrement
                                ),
                              type: "ok",
                            },
                            {
                              text: "取消",
                              onPress: () => setBid(""),
                              type: "cancel",
                            },
                          ]
                        );
                      } else if (
                        Number(newValue) >
                        data?.event.newestBid + data?.event.bidLimitPerBidding
                      ) {
                        Alert.alert(
                          "",
                          `您好，您的出價未符合競標規則，請參考網站＞常見問題＞競標問題進行查詢。\n如欲繼續競標，您的金額將會修正為下方金額。\n調整金額為：${
                            data?.event.newestBid +
                            data?.event.bidLimitPerBidding
                          }`,
                          [
                            {
                              text: "確認",
                              onPress: () =>
                                _addBid(
                                  data?.event.newestBid +
                                    data?.event.bidLimitPerBidding
                                ),
                              type: "ok",
                            },
                            {
                              text: "取消",
                              onPress: () => setBid(""),
                              type: "cancel",
                            },
                          ]
                        );
                      } else if (
                        (Number(newValue) - data?.event.newestBid) %
                          Number(data?.event.bidIncrement) !==
                        0
                      ) {
                        const calculateBidding =
                          Math.ceil(
                            (Number(newValue) - data?.event.newestBid) /
                              data?.event.bidIncrement
                          ) *
                            data?.event.bidIncrement +
                          data?.event.newestBid;
                        Alert.alert(
                          "",
                          `您好，您的出價未符合競標規則，請參考網站＞常見問題＞競標問題進行查詢。\n如欲繼續競標，您的金額將會修正為下方金額。\n調整金額為：${calculateBidding}`,
                          [
                            {
                              text: "確認",
                              onPress: () => _addBid(calculateBidding),
                              type: "ok",
                            },
                            {
                              text: "取消",
                              onPress: () => setBid(""),
                              type: "cancel",
                            },
                          ]
                        );
                      } else {
                        setBid(event.target.value);
                      }
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    disabled={data?.event.newestBidder?.id === memberId}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color={"primary"}
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      if (Boolean(bid)) {
                        Alert.alert("通知", "確定要競標嗎？", [
                          {
                            text: "確定",
                            type: "ok",
                            onPress: () =>
                              setTimeout(() => {
                                _addBid();
                              }, 0),
                          },
                          {
                            text: "返回",
                            type: "cancel",
                          },
                        ]);
                      } else {
                        Alert.notice("請輸入競標價格");
                      }
                    }}
                    disabled={data?.event.newestBidder?.id === memberId}
                  >
                    <Typography variant="h6">
                      {data?.event.newestBidder?.id === memberId
                        ? "目前您為最高出價者"
                        : "我要競標"}
                    </Typography>
                  </Button>
                </Grid>
                {moment(endTime).isAfter() && (
                  <Grid item container justify="center">
                    <DateCountdown
                      end={moment(endTime)}
                      endLabel="競標倒數"
                      onEnd={() => setEventStart(false)}
                    />
                  </Grid>
                )}
              </Fragment>
            );
          } else {
            return (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    height: 44,
                    backgroundColor: colors.red["700"],
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {(winningBidder && winningBidder.id === memberId) ||
                    winningTheAuction
                      ? "恭喜您成功競標"
                      : "競標結束"}
                  </Typography>
                </Box>
              </Grid>
            );
          }
        }
      })()}
    </Grid>
  );
}

function EventBiddings({ id }) {
  const Alert = useAlert();
  const renderFirstRef = useRef(true);

  const [getEvent, { data, loading }] = useLazyQuery(GET_EVENT_BIDDINGS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          renderFirstRef.current = false;
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getEvent({ variables: { id: Number(id) } });
    }
  }, [id, getEvent]);

  return (
    <Box>
      {loading && renderFirstRef.current ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Table
          data={Boolean(data) ? data.event.biddings : []}
          header={["出價", "姓名", "電話後3碼"]}
          columns={[
            (item) => item.bid,
            (item) => stringHiddenInTheMiddle(item.bidder.fullName, 1, 1),
            (item) => {
              const bidderMobile = item.bidder?.mobile;
              if (bidderMobile) {
                return bidderMobile.slice(
                  bidderMobile.length - 3,
                  bidderMobile.length
                );
              } else return "";
            },
          ]}
        />
      )}
    </Box>
  );
}

function RaidEventLotteryButton({
  id,
  startTime,
  endTime,
  joined,
  status,
  eventMemberTierLimit,
  type,
}) {
  const Alert = useAlert();
  const useStyles = makeStyles({
    button: {
      borderRadius: 0,
      width: "100%",
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const memberTierLimit = useSelector((state) => state.member.memberTierLimit);
  const [eventStartBefore, setEventStartBefore] = useState(false);
  const [eventCountdownTenMinute, setEventCountdownTenMinute] = useState(false);
  const [eventStart, setEventStart] = useState(false);
  const [join, setJoin] = useState(false);
  const termsDialogRef = useRef();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  useEffect(() => {
    if (typeof joined === "boolean" && joined) {
      setJoin(true);
    }
  }, [joined]);

  useEffect(() => {
    if (!moment(startTime).isBefore()) {
      setEventStartBefore(true);
    } else if (!moment(endTime).isBefore()) {
      setEventStart(true);
    }
    if (status === "READY_TO_START") {
      const firstLoginAt = moment(startTime).tz("Asia/Taipei");
      const y = moment().tz("Asia/Taipei");
      const timeSubtract = firstLoginAt.diff(y, "seconds");
      if (timeSubtract <= 600 && join) {
        setEventCountdownTenMinute(true);
      }
    }
  }, [startTime, endTime, join, status]);

  const [getEvent, { data }] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [getRaidBigScreen, { data: raidBigScreenData }] = useLazyQuery(
    GET_RAIDBIGSCREEN,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      pollInterval: 1000,
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  useEffect(() => {
    if (id) {
      getEvent({ variables: { id: Number(id) } });
    }
  }, [id, getEvent]);

  useEffect(() => {
    getRaidBigScreen({ variables: { eventId: Number(id) } });
  }, [getRaidBigScreen, id]);

  const [joinRaidEventFn, { loading: joinRaidEventLoading }] = useMutation(
    JOIN_RAIDEVENT,
    {
      onCompleted({ joinRaidEvent: { success, message } }) {
        if (success) {
          setJoin(true);
          return Alert.notice("參加團體戰成功！");
        } else if (message) {
          if (message.search("登入") > -1) {
            return Alert.alert("", message, [
              {
                text: "回首頁",
                onPress: () => history.replace("/"),
                type: "ok",
              },
              {
                text: "登入",
                onPress: () => history.replace("/login"),
                type: "cancel",
              },
            ]);
          } else {
            return Alert.notice(message);
          }
        } else {
          return Alert.notice("參加團體戰失敗，請重新嘗試！");
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  const _handleEvent = useCallback(() => {
    joinRaidEventFn({
      variables: { eventId: id },
    });
  }, [id, joinRaidEventFn]);

  const _joinRaidEvent = useCallback(() => {
    if (memberToken) {
      if (checkMemberTierLimit(memberTierLimit, eventMemberTierLimit)) {
        return setTimeout(() => {
          Alert.notice(
            `會員等級必須達到「${getMemberTier(
              eventMemberTierLimit
            )}」才有參加資格！`
          );
        }, 0);
      } else {
        termsDialogRef.current?.open();
      }
    } else {
      return Alert.alert("通知", "尚未登入，請先登入才可參加團體戰！", [
        {
          text: "登入",
          onPress: () => history.push("/login", { goBackPage: true }),
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    }
  }, [Alert, eventMemberTierLimit, history, memberTierLimit, memberToken]);

  return (
    <Grid container spacing={1}>
      <LoadingFloating loading={joinRaidEventLoading} />
      <TermsDialog ref={termsDialogRef} handleEvent={_handleEvent} />
      {(() => {
        if (data) {
          if (eventStartBefore) {
            // NOTE 活動開始前
            return (
              <Grid item container justify="center">
                <DateCountdown
                  start={moment(startTime).subtract(6, "seconds")}
                  startLabel="活動開始倒數"
                  onEnd={() =>
                    setEventStartBefore(() => {
                      setEventStart(true);
                      return false;
                    })
                  }
                  onEventCountdownTenMinute={() => {
                    setEventCountdownTenMinute(true);
                  }}
                />
                <Grid item xs={12}>
                  {eventCountdownTenMinute && join ? (
                    <Button
                      color={"primary"}
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        if (type === "RAID_EVENT") {
                          history.push(generatePath("/raid/:id", { id }));
                        } else {
                          history.push(generatePath("/PVPRaid/:id", { id }));
                        }
                      }}
                      disabled={!join || !Boolean(data) || !Boolean(data.event)}
                    >
                      <Typography variant="h6">{"點選進入遊戲畫面"}</Typography>
                    </Button>
                  ) : (
                    <Button
                      color={"primary"}
                      variant="contained"
                      className={classes.button}
                      onClick={_joinRaidEvent}
                      disabled={join}
                    >
                      <Typography variant="h6">
                        {join ? "已參加團體戰" : "參加團體戰"}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            );
          } else {
            if (eventStart) {
              return (
                <Fragment>
                  <Grid item container justify="space-evenly">
                    {/* <Typography style={{ color: "#9e9e9e" }}>
                        怪物名稱：
                        {(() => {
                          switch (data?.event.monster) {
                            case "MAO_MAO_ER":
                              return "毛毛二";
                            case "HUI_DAO":
                              return "灰島";
                            case "XIA_BO_MI":
                              return "夏波米";
                            case "INTOY":
                              return "INTOY";
                            default:
                              return null;
                          }
                        })()}
                      </Typography> */}
                    <Typography style={{ color: "#9e9e9e" }}>
                      怪物血量：
                      {Boolean(raidBigScreenData)
                        ? raidBigScreenData.raidBigScreen.currentHealthPoint
                        : data?.event.maxHealthPoint}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!join ? (
                      <Button
                        color={"primary"}
                        variant="contained"
                        className={classes.button}
                        onClick={_joinRaidEvent}
                      >
                        <Typography variant="h6">參加團體戰</Typography>
                      </Button>
                    ) : (
                      <Button
                        color={"primary"}
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          if (type === "RAID_EVENT") {
                            history.push(generatePath("/raid/:id", { id }));
                          } else {
                            history.push(generatePath("/PVPRaid/:id", { id }));
                          }
                        }}
                        disabled={!Boolean(data)}
                      >
                        <Typography variant="h6">開始團體戰</Typography>
                      </Button>
                    )}
                  </Grid>
                  {moment(endTime).isAfter() && (
                    <Grid item container justify="center">
                      <DateCountdown
                        end={moment(endTime)}
                        endLabel="團體戰倒數"
                        onEnd={() => setEventStart(false)}
                      />
                    </Grid>
                  )}
                </Fragment>
              );
            } else {
              function _label() {
                if (data.event.joined && Boolean(raidBigScreenData)) {
                  return `挑戰${
                    raidBigScreenData.raidBigScreen.status === "VICTORY"
                      ? "成功"
                      : "失敗"
                  }`;
                } else {
                  return "團體戰結束";
                }
              }
              // NOTE 團體戰結束
              return (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: 44,
                      backgroundColor: colors.red["700"],
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {_label()}
                    </Typography>
                  </Box>
                </Grid>
              );
            }
          }
        } else {
          return (
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  height: 44,
                  backgroundColor: "black",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  讀取中
                </Typography>
              </Box>
            </Grid>
          );
        }
      })()}
    </Grid>
  );
}
function DetailArea({ data }) {
  switch (data.type) {
    case "HEADING": //"標題"
      return <Typography variant="h6">{data.body}</Typography>;
    case "PARAGRAPH": //"段落"
      return (
        <Typography>
          {data.body.split("\n").map((line) => (
            <>
              {line}
              <br />
            </>
          ))}
        </Typography>
      );
    case "IMAGE": //"圖片"
      return (
        <img
          src={data.body}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            alignSelf: "flex-start",
          }}
          alt=""
        />
      );
    case "YOUTUBE_VIDEO": //"影片"
      let url = data.body;
      let embedUrl = "";
      if (url) {
        if (url.startsWith("http://") || url.startsWith("https://")) {
          if (url.search("embed") === -1) {
            if (url[8] === "w") {
              url = url.split("watch?v=")[1].split("&")[0];
            } else {
              url = url.split("youtu.be/")[1];
            }
          } else {
            embedUrl = data.body;
          }
        } else {
          return null;
        }
      }
      return (
        <iframe
          width="900"
          height="506"
          src={embedUrl || "https://www.youtube.com/embed/" + url}
          style={{ border: `0px` }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
          title="youtube"
        />
      );
    default:
      return null;
  }
}
// ANCHOR 活動排行榜
function Rankingboard({ data, type }) {
  const useStyles = makeStyles((theme) => ({
    text: {
      ...theme.typography.h6,
      fontWeight: "bold",
      color: theme.palette.grey[700],
    },
  }));
  const classes = useStyles();
  if (type === "RAID_EVENT" || type === "PVP_RAID_EVENT") {
    const rankList = data
      .map((item) => ({
        fullName: item.player.fullName
          .split("")
          .map((n, i) => (i === 1 ? "◯" : n))
          .join(""),
        score: item.score,
      }))
      .sort((a, b) => b.score - a.score)
      .filter((_, index) => index < 5);
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
      >
        <Typography className={classes.text}>RANKING</Typography>
        {rankList.map((item, index) => {
          const plusnember = 1.009 - 0.001 * index;
          return (
            <Typography className={classes.text}>
              {index + 1}.{item.fullName}---
              {(item.score * plusnember).toFixed()}
            </Typography>
          );
        })}
      </Box>
    );
  } else {
    return null;
  }
}
function PriceBrand({ id, price = 0, type }) {
  const Alert = useAlert();
  const theme = useTheme();

  const [getEvent, { data }] = useLazyQuery(GET_EVENT_NEWESTBID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getEvent({ variables: { id: Number(id) } });
    }
  }, [id, getEvent]);

  return (
    <Box
      bgcolor={colors.red["700"]}
      padding={`0 ${theme.spacing(2)}px`}
      color={theme.palette.primary.contrastText}
      alignSelf="flex-end"
    >
      {type === "AUCTION_EVENT" ? (
        <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
          目前出價金額&ensp;NTD&ensp;
          {data && thousandsSeparator(data.event.newestBid)}
        </Typography>
      ) : (
        <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
          NTD&ensp;
          {thousandsSeparator(price)}
        </Typography>
      )}
    </Box>
  );
}
function ProductImages({ data, sizeValue, index, onChangeIndex }) {
  const theme = useTheme();
  const [selectImage, setSelectImage] = useState(null);
  useEffect(() => {
    onChangeIndex(0);
  }, [sizeValue, onChangeIndex]);
  useEffect(() => {
    if (selectImage === null && Boolean(data[0])) {
      setSelectImage(data[0][0].location);
    }
  }, [data, selectImage]);
  const classes = useStyles();
  return (
    <Grid container spacing={1} alignContent="space-between">
      <Grid item xs={12}>
        <Card variant="outlined">
          <SquareBox>
            <img src={selectImage} className={classes.image} alt="" />
          </SquareBox>
        </Card>
      </Grid>
      <Grid container item>
        <SwipeableViews
          style={{ width: "100%" }}
          index={index}
          onChangeIndex={onChangeIndex}
          enableMouseEvents
        >
          {data.map((item, index) => (
            <Box padding={0.5} key={index}>
              <Grid container spacing={1}>
                {item.map((item2) => {
                  const onSelect = item2.location === selectImage;
                  return (
                    <Grid item xs={6} sm={4} md={3} key={item2.id}>
                      <Card
                        variant="outlined"
                        style={{
                          borderColor: onSelect && theme.palette.primary.main,
                          borderWidth: onSelect && 2,
                        }}
                      >
                        <CardActionArea
                          onClick={() => setSelectImage(item2.location)}
                        >
                          <SquareBox>
                            <img
                              src={item2.location}
                              className={classes.image}
                              alt=""
                            />
                          </SquareBox>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ))}
        </SwipeableViews>
      </Grid>
    </Grid>
  );
}
