// SECTION 伺服器資料
// NOTE 訂單狀態
/**- 訂單狀態*/
export const orderStatusEnum = {
  ALL: "全部",
  WAITING_FOR_PAYMENT: "等待付款",
  PROCESSING: "完成預購",
  PAID: "完成尾款",
  CANCELLED: "已取消",
};
// NOTE 訂單狀態列表
/**- 訂單狀態列表*/
export const orderStatusList = [
  {
    label: "全部",
    value: "ALL",
  },
  { label: "等待付款", value: "WAITING_FOR_PAYMENT" },
  { label: "完成預購", value: "PROCESSING" },
  { label: "完成尾款", value: "PAID" },
  { label: "已取消", value: "CANCELLED" },
];

// NOTE 訂單品項狀態
/**- 訂單品項狀態*/
export const orderItemStatusEnum = {
  WAITING: "待出貨",
  WAITING_FOR_SHIPMENT_FEE: "待付運費",
  READY_FOR_SHIPMENT: "可出貨",
  SHIPPED: "已出貨",
};
// NOTE 訂單品項狀態列表
/**- 訂單品項狀態列表*/
export const orderItemStatusList = [
  { label: "待出貨", value: "WAITING" },
  { label: "待付運費", value: "WAITING_FOR_SHIPMENT_FEE" },
  { label: "可出貨", value: "READY_FOR_SHIPMENT" },
  { label: "已出貨", value: "SHIPPED" },
];

// NOTE 出貨狀態
/**- 出貨狀態*/
export const shipmentStatusEnum = {
  ALL: "全部",
  WAITING_FOR_PAYMENT: "等待付款",
  WAITING_FOR_SHIPMENT: "待出貨",
  SHIPPED: "已出貨",
};
// NOTE 出貨狀態列表
/**- 出貨狀態列表*/
export const shipmentStatusList = [
  {
    label: "全部",
    value: "ALL",
  },
  { label: "等待付款", value: "WAITING_FOR_PAYMENT" },
  { label: "待出貨", value: "WAITING_FOR_SHIPMENT" },
  { label: "已出貨", value: "SHIPPED" },
];

// NOTE 運送方式
/**- 運送方式*/
export const shipmentMethodEnum = {
  ALL: "全部",
  CONVENIENCE_STORE: "超商(7-11)",
  HOME_DELIVERY: "宅配",
  SELF_PICKUP: "自取",
};
// NOTE 運送方式列表
/**- 運送方式列表*/
export const shipmentMethodList = [
  {
    label: "全部",
    value: "ALL",
  },
  { label: "超商(7-11)", value: "CONVENIENCE_STORE" },
  { label: "宅配", value: "HOME_DELIVERY" },
  { label: "自取", value: "SELF_PICKUP" },
];

// NOTE 活動類型
/**- 活動類型*/
export const eventTypeEnum = {
  DRAWING_LOTS_EVENT: "抽選活動",
  AUCTION_EVENT: "競標活動",
  RAID_EVENT: "團體戰活動",
  PVP_RAID_EVENT: "對戰團體戰活動",
  RAISING_EVENT: "育成活動",
};
// NOTE 活動類型列表
/**- 活動類型列表*/
export const eventTypeList = [
  {
    label: "抽選活動",
    value: "DRAWING_LOTS_EVENT",
  },
  { label: "競標活動", value: "AUCTION_EVENT" },
  { label: "團體戰活動", value: "RAID_EVENT" },
  { label: "對戰團體戰活動", value: "PVP_RAID_EVENT" },
];

// NOTE 對戰隊伍
/**- 對戰隊伍*/
export const pvpTeamEnum = {
  PIRATE: "海盜",
  ADVENTURER: "冒險家",
};
// NOTE 對戰隊伍列表
/**- 對戰隊伍列表*/
export const pvpTeamList = [
  {
    label: "海盜",
    value: "PIRATE",
  },
  { label: "冒險家", value: "ADVENTURER" },
];

// NOTE 團體戰遊戲狀態
/**- 團體戰遊戲狀態*/
export const raidStatusEnum = {
  READY_TO_START: "遊戲未開始",
  DURING_THE_GAME: "遊戲中",
  VICTORY: "挑戰成功",
  DEFEAT: "挑戰失敗",
};
// NOTE 團體戰遊戲狀態列表
/**- 團體戰遊戲狀態列表*/
export const raidStatusList = [
  {
    label: "遊戲未開始",
    value: "READY_TO_START",
  },
  { label: "遊戲中", value: "DURING_THE_GAME" },
  {
    label: "挑戰成功",
    value: "VICTORY",
  },
  { label: "挑戰失敗", value: "DEFEAT" },
];

// NOTE 啟用狀態列表
/**- 啟用狀態列表*/
export const enabledList = [
  {
    label: "啟用",
    value: true,
  },
  { label: "關閉", value: false },
];

// NOTE 育成主題
/**- 育成主題列表*/
export const themeList = [
  {
    label: "空島",
    value: 1,
  },
  { label: "神秘島", value: 2 },
  { label: "漆黑都市", value: 3 },
  { label: "平行時空", value: 4 },
  { label: "蘿蔔島", value: 5 },
];
/**- 育成主題列舉*/
export const themeEnum = {
  1: "FloatingIsland",
  2: "MysticIsland",
  3: "DarkCity",
  4: "ParallelUniverse",
  5: "RadishIsland",
};
/**- 育成主題顯示列舉*/
export const themeDisplayEnum = {
  1: "空島",
  2: "神秘島",
  3: "漆黑都市",
  4: "平行時空",
  5: "蘿蔔島",
};

// NOTE 付款方式列表
/**- 付款方式列表*/
export const paymentMethodList = [
  {
    label: "信用卡",
    value: "CREDIT_CARD",
  },
  { label: "ATM 櫃員機", value: "ATM" },
];
// NOTE 付款方式
/**- 付款方式*/
export const paymentMethodEnum = {
  CREDIT_CARD: "信用卡",
  ATM: "ATM 櫃員機",
};

// NOTE 商品類型列表
/**- 商品類型列表*/
export const productTypeList = [
  {
    label: "一般商品",
    value: "NORMAL",
  },
  { label: "限定商品", value: "EXCLUSIVE" },
  { label: "預購商品", value: "PREORDER" },
  { label: "限時商品", value: "FLASHSALE" },
  { label: "限量商品", value: "LIMIT" },
  { label: "社團限定", value: "CLUB_EXCLUSIVE" },
];
