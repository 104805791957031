import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
  memo,
} from "react";
// material
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  InputAdornment,
  FormLabel,
  Divider,
  ButtonBase,
  makeStyles,
  useTheme,
  useMediaQuery,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
// icon
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// apollo
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
  useFieldArray,
} from "react-hook-form";
// date-fns
import { format, parseISO, differenceInMinutes, isBefore } from "date-fns";
import moment from "moment";
// react-player
import ReactPlayer from "react-player";

// component
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import {
  Select,
  InputTitle,
  CustomDateSelect,
  CustomNumberInput,
  FieldCache,
} from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import Text from "../../component/Text";
import TabView from "../../component/TabView";
import AdaptiveImage from "../../component/AdaptiveImage";
import CustomUploadFiles from "../../component/CustomUploadFiles";
import Image from "../../component/Image";
import { excelDownload } from "../../component/ExcelDownload";
// utils
import intlNumberFormat from "../../utils/intlNumberFormat";
import emptyArray from "../../utils/emptyArray";
import { themeDisplayEnum, themeList } from "../../utils/localData";
// component
import EggTypeManage from "./EggTypeManage";
import ToyManage from "./ToyManage";
import DrawingLotsResultManage from "./DrawingLotsResultManage";
import FeedCodeManage from "./FeedCodeManage";
import HatchingCodeManage from "./HatchingCodeManage";
import HatchingDescription from "./HatchingDescription";

// SECTION apollo
// NOTE 活動列表
const GET_EVENTS = gql`
  query events(
    $eventTypes: [EventType]
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    events(
      eventTypes: $eventTypes
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      # "活動總數"
      eventCount
      # "總頁數"
      pageCount
      # "活動"
      events {
        id
        # "名稱"
        name
        # "開始時間"
        startTime
        # "結束時間"
        endTime
        # "已上架"
        activated
        # "建立日期"
        createdAt
        # "更新日期"
        updatedAt
        # "繳費期限(分鐘)"
        paymentMinuteLimit
        ... on RaisingEvent {
          # "票價"
          ticketFare
          # "培育時間(目前為分鐘)"
          hatchingTime
          # "主題"
          theme
        }
      }
    }
  }
`;
// NOTE 活動
const GET_EVENT = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      # "名稱"
      name
      # "開始時間"
      startTime
      # "結束時間"
      endTime
      # "已上架"
      activated
      # "建立日期"
      createdAt
      # "更新日期"
      updatedAt
      ... on RaisingEvent {
        # "活動總券數"
        maxNumberOfTicket
        # "每人購買券數上限"
        maxNumberOfTicketPerPerson
        # "票價"
        ticketFare
        # "培育時間(目前為分鐘)"
        hatchingTime
        # "抽選方案"
        drawLotsPlans {
          id
          # "票數"
          ticket
          # "次數"
          times
        }
        # "可出貨時間"
        dateOfShipped
        # "封面url"
        coverImageUrl
        # "主題"
        theme
        # "餵食冷卻時間"
        feedingCoolDownTime
        # "餵食成功機率"
        feedingSuccessRate
      }
    }
  }
`;
// NOTE 活動
const GET_EVENT_MEMBER_ORDERS = gql`
  query event($id: Int!) {
    eventMemberOrders: event(id: $id) {
      id
      ... on RaisingEvent {
        # "參與人"
        participants {
          # "會員"
          member {
            id
            # "姓名"
            fullName
          }
          # "訂單"
          orders {
            id
            # "訂單編號"
            serialNumber
            # "品項"
            items {
              id
              # "商品"
              product {
                id
                # "名稱"
                name
              }
              # "蛋種"
              eggType {
                id
                # "名稱"
                name
              }
            }
          }
        }
      }
    }
  }
`;
// NOTE 活動輪詢間隔
const GET_EVENT_POLLINTERVAL = gql`
  query event($id: Int!) {
    eventPollInterval: event(id: $id) {
      id
      ... on RaisingEvent {
        # "剩餘券數"
        restTicket
        # "銷售券數"
        sales
        # "未繳費券數"
        numberOfUnpaid
        # "未使用券數"
        numberOfUnused
      }
    }
  }
`;
// NOTE 活動-會員持有的玩具
const GET_EVENT_MEMBEREGGS = gql`
  query eventMemberEggs($eventId: Int!) {
    eventMemberEggs(eventId: $eventId) {
      id
      # "會員"
      member {
        id
        # "姓名"
        fullName
        # "手機號碼"
        mobile
      }
      # "玩具"
      toy {
        id
        # "商品"
        product {
          id
          # "名稱"
          name
        }
      }
    }
  }
`;
// NOTE 隱藏活動
const HIDE_EVENT = gql`
  mutation hideEvent($id: Int!) {
    hideEvent(id: $id) {
      success
      message
    }
  }
`;
// NOTE 儲存育成活動
const SAVE_RAISING_EVENT = gql`
  mutation saveRaisingEvent($raisingEventInput: RaisingEventInput!) {
    saveRaisingEvent(raisingEventInput: $raisingEventInput) {
      success
      message
    }
  }
`;
// NOTE 結束育成活動
const FINISH_RAISING_EVENT = gql`
  mutation finishRaisingEvent($id: Int!) {
    finishRaisingEvent(id: $id) {
      success
      message
    }
  }
`;
// NOTE 上下架活動
const ACTIVATE_EVENT = gql`
  mutation activateEvent($eventId: Int!, $activated: Boolean!) {
    activateEvent(eventId: $eventId, activated: $activated) {
      success
      message
    }
  }
`;
// !SECTION
// NOTE reducerDispatch
function reducerDispatch(state, action) {
  switch (action.type) {
    case "add":
    case "edit":
    case "content":
      return action.data;
    default:
      return null;
  }
}
// ANCHOR 主要組件
export default function HatchingManage() {
  const [swtichContent, swtichContentDispatch] = useReducer(
    reducerDispatch,
    null
  );
  const { control, setValue } = useForm({
    defaultValues: {
      listRefetch: false,
      contentRefetch: false,
    },
  });

  switch (swtichContent?.swtichType) {
    case "add":
      return (
        <HatchingForm
          swtichContentDispatch={swtichContentDispatch}
          formSetValue={setValue}
        />
      );
    case "edit":
      return swtichContent?.id ? (
        <HatchingForm
          swtichContent={swtichContent}
          swtichContentDispatch={swtichContentDispatch}
          formSetValue={setValue}
        />
      ) : null;
    case "content":
      return swtichContent?.id ? (
        <HatchingContent
          swtichContent={swtichContent}
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
        />
      ) : null;
    default:
      return (
        <HatchingList
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
        />
      );
  }
}
// ANCHOR 列表
function HatchingList({
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
}) {
  const listRefetch = useWatch({
    control: formControl,
    name: "listRefetch",
  });
  const { alert, notice } = useAlert();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [latest, setLatest] = useState(true);

  const {
    data: eventsData,
    loading: eventsLoading,
    refetch,
  } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      eventTypes: ["RAISING_EVENT"],
      page,
      pageSize,
      latest,
    },
    onCompleted({ events }) {
      if (events) {
        setTimeout(() => {
          formSetValue("listRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (listRefetch) {
      refetch();
    }
  }, [listRefetch, refetch]);

  const [hideEventFn, { loading: hideEventLoading }] = useMutation(HIDE_EVENT, {
    onCompleted({ hideEvent: { message } }) {
      if (message) {
        notice(message);
      } else {
        notice("刪除成功");
        refetch();
      }
    },
    onError() {
      return null;
    },
  });

  const _hideEvent = useCallback(
    ({ id, name }) => {
      alert("", `確定要刪除活動「${name}」？`, [
        {
          text: "確定",
          onPress: () => {
            hideEventFn({
              variables: {
                id,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, hideEventFn]
  );

  if (eventsLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <Card>
        <LoadingModal loading={hideEventLoading} />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              育成活動數量：{eventsData?.events?.eventCount || 0}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onPress={() =>
                swtichContentDispatch({
                  type: "add",
                  data: {
                    swtichType: "add",
                  },
                })
              }
            >
              新增育成活動
            </Button>
          </Box>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <Select
                label={"排序"}
                items={[
                  { label: "從新到舊", value: true },
                  { label: "從舊到新", value: false },
                ]}
                value={latest}
                onChange={(value) => setLatest(value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"每頁顯示"}
                items={[
                  { label: "10", value: 10 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                value={pageSize}
                onChange={(value) => setPageSize(value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Table
            data={emptyArray(eventsData?.events?.events)}
            header={[
              "活動名稱",
              "主題",
              "路遙券售價",
              "孵化時間",
              "開始時間",
              "結束時間",
              "建立時間",
              "最後更新時間",
              "狀態",
              "操作",
            ]}
            tableWidth={3}
            columns={[
              "name",
              (item) => themeDisplayEnum[item.theme] || "空島",
              (item) => intlNumberFormat(item.ticketFare, true),
              (item) => `${item.hatchingTime}分`,
              (item) =>
                item.startTime &&
                format(parseISO(item.startTime), "yyyy/LL/dd HH:mm"),
              (item) =>
                item.endTime &&
                format(parseISO(item.endTime), "yyyy/LL/dd HH:mm"),
              (item) =>
                item.createdAt &&
                format(parseISO(item.createdAt), "yyyy/LL/dd HH:mm"),
              (item) =>
                item.updatedAt &&
                format(parseISO(item.updatedAt), "yyyy/LL/dd HH:mm"),
              (item) => (item.activated ? "已上架" : "已下架"),
              (item) => (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onPress={(e) => {
                        e.stopPropagation();
                        _hideEvent(item);
                      }}
                    >
                      刪除
                    </Button>
                  </Grid>
                </Grid>
              ),
            ]}
            onPress={(item) => {
              swtichContentDispatch({
                type: "content",
                data: {
                  id: item.id,
                  swtichType: "content",
                },
              });
            }}
          />
        </CardContent>
        <CardActions>
          <Grid container justify="center">
            <Pagination
              count={eventsData?.events?.pageCount || 1}
              size="large"
              color="primary"
              page={page}
              onChange={(e, n) => setPage(n)}
              showFirstButton
              showLastButton
            />
          </Grid>
        </CardActions>
      </Card>
    );
  }
}
// ANCHOR form
function HatchingForm({
  swtichContent,
  swtichContentDispatch = () => {},
  formSetValue,
}) {
  const form = useForm({
    defaultValues: {
      coverImageUrl: "",
      theme: null,
      name: "",
      ticketFare: "",
      maxNumberOfTicketPerPerson: "",
      maxNumberOfTicket: "",
      hatchingTime: "",
      startTime: null,
      endTime: null,
      dateOfShipped: null,
      drawLotsPlans: [],
      paymentMinuteLimit: "",
      feedingCoolDownTime: "",
      feedingSuccessRate: "",
    },
  });
  const { reset, setValue } = form;

  const [getEvent, { loading: eventLoading }] = useLazyQuery(GET_EVENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          Object.keys(event).forEach((item) => {
            if (
              item !== "__typename" &&
              item !== "createdAt" &&
              item !== "updatedAt" &&
              item !== "activated"
            ) {
              if (item === "drawLotsPlans") {
                const newDrawLotsPlans = [];
                emptyArray(event[item]).forEach(
                  ({ __typename, id, ...otherEvents }) => {
                    newDrawLotsPlans.push(otherEvents);
                  }
                );
                setValue(
                  item,
                  newDrawLotsPlans.length > 0
                    ? newDrawLotsPlans
                    : [{ ticket: "", times: null }]
                );
              } else {
                setValue(item, event[item]);
              }
            }
          });
        }, 300);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (swtichContent?.id) {
      getEvent({ variables: { id: swtichContent.id } });
    }
  }, [swtichContent, getEvent]);

  const _goBack = useCallback(
    (e) => {
      if (e === "refetch") {
        swtichContentDispatch({
          type: "list",
        });
      } else if (swtichContent?.id) {
        swtichContentDispatch({
          type: "content",
          data: {
            id: swtichContent.id,
            swtichType: "content",
          },
        });
      } else {
        swtichContentDispatch({
          type: "list",
        });
      }
      reset();
    },
    [swtichContentDispatch, reset, swtichContent]
  );

  if (eventLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Card>
      <FormProvider {...form}>
        <CardContent>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={_goBack} size="small">
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography style={{ paddingLeft: "10px" }}>{`${
              swtichContent?.id ? "編輯" : "新增"
            }育成活動`}</Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item style={{ width: "270px" }}>
              <Controller
                name="coverImageUrl"
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="封面圖" required />
                    <AdaptiveImage ratio="100%">
                      <CustomUploadFiles
                        name="coverImageUrl"
                        render={() => (
                          <Image
                            src={field?.value}
                            style={{ width: "100%", height: "100%" }}
                            resizeMode={"cover"}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid container item flex={1} />
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="theme"
                render={({ field, fieldState: { error } }) => (
                  <Box width="100%">
                    <InputTitle label="主題" required />
                    <Select
                      {...field}
                      items={themeList}
                      fullWidth
                      error={Boolean(error)}
                    />
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="name"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="名稱" required />
                    <TextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="ticketFare"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="路遙券售價" required />
                    <CustomNumberInput
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="maxNumberOfTicketPerPerson"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="路遙券個人購買上限" required />
                    <CustomNumberInput
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="maxNumberOfTicket"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="路遙券團體購買上限" required />
                    <CustomNumberInput
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="hatchingTime"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="孵化時間" required />
                    <CustomNumberInput
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">分</InputAdornment>
                        ),
                        inputProps: {
                          style: { textAlign: "right" },
                        },
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="startTime"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="開始時間" required />
                    <CustomDateSelect
                      {...field}
                      onAccept={(e) => {
                        setValue("endTime", null);
                        field.onChange(e);
                      }}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                      minDate={new Date()}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FieldCache
                name="startTime"
                render={(startTime) => (
                  <Controller
                    name="endTime"
                    rules={{
                      required: "必填欄位 !",
                      validate: (endTime) => {
                        const isValidDate = isBefore(
                          new Date(startTime),
                          new Date(endTime)
                        );
                        return isValidDate || "結束時間必須為開始時間之後 !";
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <div>
                        <InputTitle label="結束時間" required />
                        <CustomDateSelect
                          {...field}
                          onAccept={field.onChange}
                          error={error}
                          helperText={error?.message}
                          fullWidth
                          minDate={new Date(startTime)}
                          disabled={!startTime}
                        />
                      </div>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FieldCache
                name="startTime"
                render={(startTime) => (
                  <Controller
                    name="dateOfShipped"
                    rules={{
                      required: "必填欄位 !",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <div>
                        <InputTitle label="可出貨時間" required />
                        <CustomDateSelect
                          {...field}
                          onAccept={field.onChange}
                          error={error}
                          helperText={error?.message}
                          fullWidth
                          minDate={new Date(startTime)}
                          disabled={!startTime}
                        />
                      </div>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="paymentMinuteLimit"
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="繳費期限(分鐘)" />
                    <CustomNumberInput
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid container item xs={12}>
              <InputTitle label="抽選方案" />
              <DrawLotsPlansComponent />
            </Grid>
            <Grid container item xs={12}>
              <InputTitle label="餵食設定" />
              <FeedSettings />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ToolButton _goBack={_goBack} formSetValue={formSetValue} />
        </CardActions>
      </FormProvider>
    </Card>
  );
}
function DrawLotsPlansComponent() {
  const { fields, append, remove } = useFieldArray({ name: "drawLotsPlans" });
  const _addDrawingLots = useCallback(() => {
    append({
      ticket: "",
      times: null,
    });
  }, [append]);
  const _renderDrawLotsPlan = useCallback(
    (_, index) => <DrawLotsPlan index={index} remove={remove} />,
    [remove]
  );
  return (
    <Grid container item spacing={3} style={{ marginTop: 5 }}>
      {fields.map(_renderDrawLotsPlan)}
      <Grid item xs={12} md={6}>
        <InputTitle label="新增抽選方案" />
        <ButtonBase
          style={{
            minHeight: "40px",
            width: "100%",
            border: "1px solid #9c9c9c",
            borderRadius: "5px",
          }}
          onClick={_addDrawingLots}
        >
          <AddIcon />
        </ButtonBase>
      </Grid>
    </Grid>
  );
}
function DrawLotsPlan({ index, remove }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const useStyles = makeStyles({
    deleteIconBox: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "#808080",
        "& svg": {
          color: "white !important",
        },
      },
    },
  });
  const classes = useStyles();
  return (
    <Grid container item xs={12} md={6}>
      <Grid item xs={12} sm={6}>
        <Controller
          name={`drawLotsPlans.${index}.ticket`}
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <div
              style={{
                paddingRight: !isMobile && "12px",
                paddingBottom: isMobile && "12px",
              }}
            >
              <InputTitle label="使用券數" required>
                {isMobile && index !== 0 && (
                  <Box
                    className={classes.deleteIconBox}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <HighlightOffIcon style={{ color: "#707070" }} />
                  </Box>
                )}
              </InputTitle>
              <CustomNumberInput
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
              />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name={`drawLotsPlans.${index}.times`}
          render={({ field, fieldState: { error } }) => (
            <div
              style={{
                paddingLeft: !isMobile && "12px",
                paddingTop: isMobile && "12px",
              }}
            >
              <InputTitle label="贈送蛋數">
                {!isMobile && index !== 0 && (
                  <Box
                    className={classes.deleteIconBox}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <HighlightOffIcon style={{ color: "#707070" }} />
                  </Box>
                )}
              </InputTitle>
              <CustomNumberInput
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
              />
            </div>
          )}
        />
      </Grid>
    </Grid>
  );
}

/** - 餵食設定 */
const FeedSettings = memo(function FeedSettings() {
  return (
    <Grid container item spacing={3} style={{ marginTop: 5 }}>
      <Grid item xs={12} sm={6} md={3}>
        <Controller
          name="feedingCoolDownTime"
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <InputTitle label="餵食冷卻時間(分鐘)" required />
              <CustomNumberInput
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                decimalScale={0}
              />
            </>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Controller
          name="feedingSuccessRate"
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <InputTitle label="餵食成功機率(%)" required />
              <CustomNumberInput
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
              />
            </>
          )}
        />
      </Grid>
    </Grid>
  );
});

// ANCHOR 按鈕
function ToolButton({ _goBack = () => {}, formSetValue }) {
  const { notice } = useAlert();
  const { handleSubmit } = useFormContext();
  const [saveRaisingEventFn, { loading: saveRaisingEventLoading }] =
    useMutation(SAVE_RAISING_EVENT, {
      onCompleted({ saveRaisingEvent: { message } }) {
        if (message) {
          notice(message);
        } else {
          formSetValue("listRefetch", true);
          formSetValue("contentRefetch", true);
          notice("成功");
          _goBack("refetch");
        }
      },
      onError() {
        return null;
      },
    });
  const _submit = useCallback(
    ({
      ticketFare,
      maxNumberOfTicket,
      maxNumberOfTicketPerPerson,
      hatchingTime,
      drawLotsPlans,
      coverImageUrl,
      theme,
      paymentMinuteLimit,
      feedingCoolDownTime,
      feedingSuccessRate,
      ...otherDatas
    }) => {
      const raisingEventInput = {
        ...otherDatas,
        ticketFare: parseFloat(ticketFare),
        maxNumberOfTicket: parseInt(maxNumberOfTicket, 10),
        maxNumberOfTicketPerPerson: parseInt(maxNumberOfTicketPerPerson, 10),
        hatchingTime: parseInt(hatchingTime, 10),
        drawLotsPlans: drawLotsPlans.map(({ ticket, times }) => ({
          ticket: parseInt(ticket, 10),
          times: times ? parseInt(times, 10) : 0,
        })),
        paymentMinuteLimit: paymentMinuteLimit
          ? parseInt(paymentMinuteLimit, 10)
          : undefined,
        feedingCoolDownTime: parseInt(feedingCoolDownTime, 10),
        feedingSuccessRate: parseFloat(feedingSuccessRate),
      };
      if (coverImageUrl) {
        raisingEventInput.coverImageUrl = coverImageUrl;
      }
      if (theme) {
        raisingEventInput.theme = theme;
      }
      saveRaisingEventFn({
        variables: {
          raisingEventInput,
        },
      });
    },
    [saveRaisingEventFn]
  );
  const _onCancel = useCallback(() => {
    _goBack();
  }, [_goBack]);
  return (
    <>
      <LoadingModal loading={saveRaisingEventLoading} />
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onPress={handleSubmit(_submit)}
          >
            儲存
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onPress={_onCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
// ANCHOR 內容管理
function HatchingContent({
  swtichContent,
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
}) {
  const contentRefetch = useWatch({
    control: formControl,
    name: "contentRefetch",
  });
  const { alert, notice } = useAlert();
  const [getEvent, { data: eventData, loading: eventLoading, refetch }] =
    useLazyQuery(GET_EVENT, {
      fetchPolicy: "network-only",
      onCompleted({ event }) {
        if (event) {
          setTimeout(() => {
            formSetValue("contentRefetch", false);
          }, 0);
        }
      },
      onError() {
        return null;
      },
    });

  useEffect(() => {
    if (contentRefetch && refetch) {
      refetch();
    }
  }, [contentRefetch, refetch]);
  useEffect(() => {
    if (swtichContent?.id) {
      getEvent({ variables: { id: swtichContent.id } });
    }
  }, [swtichContent, getEvent]);
  const hatching = useMemo(() => eventData?.event || {}, [eventData]);

  const [finishRaisingEventFn, { loading: finishRaisingEventLoading }] =
    useMutation(FINISH_RAISING_EVENT, {
      onCompleted({ finishRaisingEvent: { message } }) {
        if (message) {
          notice(message);
        } else {
          formSetValue("listRefetch", true);
          refetch();
        }
      },
      onError() {
        return null;
      },
    });

  const _finishRaisingEvent = useCallback(
    ({ id }) => {
      alert("", `確定要提前結束活動？`, [
        {
          text: "確定",
          onPress: () => {
            finishRaisingEventFn({
              variables: {
                id,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, finishRaisingEventFn]
  );

  const [activateEventFn, { loading: activateEventLoading }] = useMutation(
    ACTIVATE_EVENT,
    {
      onCompleted({ activateEvent: { message } }) {
        if (message) {
          notice(message);
        } else {
          formSetValue("listRefetch", true);
          refetch();
        }
      },
      onError() {
        return null;
      },
    }
  );

  const _activateEvent = useCallback(
    ({ id }) => {
      alert("", `確定要上架活動？`, [
        {
          text: "確定",
          onPress: () => {
            activateEventFn({
              variables: {
                eventId: id,
                activated: true,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, activateEventFn]
  );

  const _goBack = useCallback(() => {
    swtichContentDispatch({
      type: "list",
    });
  }, [swtichContentDispatch]);

  if (eventLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Card>
      <LoadingModal
        loading={finishRaisingEventLoading || activateEventLoading}
      />
      <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={_goBack} size="small">
          <KeyboardBackspaceIcon />
        </IconButton>
        <Button
          color="primary"
          variant="contained"
          onPress={() => {
            swtichContentDispatch({
              type: "edit",
              data: {
                id: swtichContent?.id,
                swtichType: "edit",
              },
            });
          }}
        >
          編輯
        </Button>
      </CardActions>
      <CardContent>
        <Grid container item justify="center">
          <Text type="h4">育成活動</Text>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>主題</FormLabel>
            <Text>{themeDisplayEnum[hatching.theme] || "空島"}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>路遙券售價</FormLabel>
            <Text>{intlNumberFormat(hatching.ticketFare, true)}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>路遙券個人購買上限</FormLabel>
            <Text>{intlNumberFormat(hatching.maxNumberOfTicketPerPerson)}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>路遙券團體購買上限</FormLabel>
            <Text>{intlNumberFormat(hatching.maxNumberOfTicket)}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>孵化時間</FormLabel>
            <Text>{intlNumberFormat(hatching.hatchingTime)}分</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>開始時間</FormLabel>
            <Text>
              {hatching.startTime &&
                format(parseISO(hatching.startTime), "yyyy/LL/dd HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>結束時間</FormLabel>
            <Text>
              {hatching.endTime &&
                format(parseISO(hatching.endTime), "yyyy/LL/dd HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>建立日期</FormLabel>
            <Text>
              {hatching.createdAt &&
                format(parseISO(hatching.createdAt), "yyyy/LL/dd HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>最後更新日期</FormLabel>
            <Text>
              {hatching.updatedAt &&
                format(parseISO(hatching.updatedAt), "yyyy/LL/dd HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>可出貨時間</FormLabel>
            <Text>
              {hatching.dateOfShipped &&
                format(parseISO(hatching.dateOfShipped), "yyyy/LL/dd HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel>狀態</FormLabel>
            <Text>{hatching.activated ? "已上架" : "已下架"}</Text>
          </Grid>
          {emptyArray(hatching.drawLotsPlans).length > 0 && (
            <>
              <Grid item xs={12}>
                <FormLabel>抽選方案</FormLabel>
              </Grid>
              {emptyArray(hatching.drawLotsPlans).map((item) => (
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormLabel>使用券數</FormLabel>
                    <Text>{item.ticket}</Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormLabel>贈送蛋數</FormLabel>
                    <Text>{item.times}</Text>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12}>
            <FormLabel>餵食設定</FormLabel>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>餵食冷卻時間(分鐘)</FormLabel>
              <Text>{hatching.feedingCoolDownTime}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>餵食成功機率(%)</FormLabel>
              <Text>{hatching.feedingSuccessRate}</Text>
            </Grid>
          </Grid>
          {hatching.activated ? (
            <Grid container item>
              <Grid item sm={4} />
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    _finishRaisingEvent(hatching);
                  }}
                  fullWidth
                >
                  結束活動
                </Button>
              </Grid>
              <Grid item sm={4} />
            </Grid>
          ) : hatching.endTime &&
            differenceInMinutes(new Date(), parseISO(hatching.endTime)) > -1 ? (
            <Grid container item>
              <Grid item sm={4} />
              <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                <Text type="h5" color="red">
                  活動已結束
                </Text>
              </Grid>
              <Grid item sm={4} />
            </Grid>
          ) : (
            <Grid container item>
              <Grid item sm={4} />
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    _activateEvent(hatching);
                  }}
                  fullWidth
                >
                  上架活動
                </Button>
              </Grid>
              <Grid item sm={4} />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormLabel>即時取得券數相關資料</FormLabel>
          </Grid>
          <TicketInformation
            id={swtichContent.id}
            activated={hatching.activated}
          />
        </Grid>
      </CardContent>
      <Box
        style={{
          height: "100%",
          width: "100%",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Divider />
      </Box>
      <CardContent>
        <HatchingOrderSwitch eventId={swtichContent?.id} />
      </CardContent>
    </Card>
  );
}

function TicketInformation({ id, activated }) {
  const isStartPollRef = useRef(false);
  const [getEventPollInterval, { data, startPolling, stopPolling }] =
    useLazyQuery(GET_EVENT_POLLINTERVAL, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted({ eventPollInterval }) {
        if (eventPollInterval) {
          setTimeout(() => {
            if (!isStartPollRef.current && activated) {
              startPolling(1000);
              isStartPollRef.current = true;
            }
          }, 0);
        }
      },
      onError() {
        return null;
      },
    });
  useEffect(() => {
    if (id) {
      getEventPollInterval({ variables: { id } });
    }
  }, [getEventPollInterval, id]);
  useEffect(() => {
    return () => {
      if (stopPolling && isStartPollRef.current) {
        isStartPollRef.current = false;
        stopPolling();
      }
    };
  }, [stopPolling]);
  const eventPollInterval = useMemo(
    () => data?.eventPollInterval || {},
    [data]
  );
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormLabel>剩餘券數</FormLabel>
        <Text>{eventPollInterval.restTicket}</Text>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormLabel>銷售券數</FormLabel>
        <Text>{eventPollInterval.sales}</Text>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormLabel>未繳費券數</FormLabel>
        <Text>{eventPollInterval.numberOfUnpaid}</Text>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormLabel>未使用券數</FormLabel>
        <Text>{eventPollInterval.numberOfUnused}</Text>
      </Grid>
    </Grid>
  );
}

// NOTE 訂單顯示切換
const orderTabViewData = ["育成資料", "會員訂單", "會員持有的玩具"];
function HatchingOrderSwitch({ eventId }) {
  const { control } = useForm({
    defaultValues: {
      select: 0,
    },
  });
  return (
    <Grid>
      <Controller
        control={control}
        name="select"
        render={({ field }) => (
          <TabView
            paper
            tabs={orderTabViewData}
            index={field.value}
            onChange={field.onChange}
          >
            <HatchingContentTable eventId={eventId} />
            <HatchingOrderDisplay eventId={eventId} />
            <HatchingMemberOwnedToys eventId={eventId} />
          </TabView>
        )}
      />
    </Grid>
  );
}

// NOTE 會員訂單
function HatchingOrderDisplay({ eventId }) {
  const [getEventMemberOrders, { data, loading }] = useLazyQuery(
    GET_EVENT_MEMBER_ORDERS,
    {
      fetchPolicy: "network-only",
      onError() {
        return null;
      },
    }
  );
  useEffect(() => {
    if (eventId) {
      getEventMemberOrders({ variables: { id: eventId } });
    }
  }, [getEventMemberOrders, eventId]);
  const eventMemberOrders = useMemo(
    () => emptyArray(data?.eventMemberOrders?.participants),
    [data]
  );
  if (loading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Box minHeight={`350px`} width="100%">
      <DropdownItem data={eventMemberOrders} />
    </Box>
  );
}

const isDrawingLotsTitles = ["姓名", "訂單與商品"];
function DropdownItem({ data = [] }) {
  const useStyles = makeStyles({
    accordion: {
      overflow: "hidden",
    },
    listPadding: {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  });
  const classes = useStyles();
  const isDrawingLotsContents = data.map((item) => ({
    fullName: item.member.fullName,
    orderAndItems: item.orders
      .map(
        (item2) =>
          `${item2.serialNumber}➜${
            item2.items[0]?.eggType?.name || item2.items[0]?.product?.name || ""
          }`
      )
      .join("、"),
  }));

  const totalOrderAmount = useMemo(() => {
    let newTotalOrderAmount = 0;
    data.forEach((item) => {
      newTotalOrderAmount += item.orders.length;
    });
    return newTotalOrderAmount;
  }, [data]);

  return (
    <Fragment>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Typography>參與人數：{data?.length}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography>訂單總數：{totalOrderAmount}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <AccordionContent
              titles={isDrawingLotsTitles}
              contents={isDrawingLotsContents}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

function AccordionContent({ titles = [], contents = [] }) {
  const useStyles = makeStyles({
    listPadding: {
      paddingTop: "3px",
      paddingBottom: "3px",
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
  });
  const classes = useStyles();
  function _renderTitleItem(title) {
    return (
      <Grid item xs={3} sm={3}>
        <Typography>{title}</Typography>
      </Grid>
    );
  }
  const _renderContentItem = useCallback(
    (item, index) => (
      <Grid container direction={"row"} item xs={12}>
        {Object.keys(item).map((item2, index2) => {
          if (index2 === 0) {
            return (
              <Grid item xs={12} sm={3} classes={{ root: classes.listPadding }}>
                <Typography>{contents[index][item2]}</Typography>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={12} sm={9} classes={{ root: classes.listPadding }}>
                <Typography>{contents[index][item2]}</Typography>
              </Grid>
            );
          }
        })}
        <Box
          style={{
            height: "100%",
            width: "100%",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Divider />
        </Box>
      </Grid>
    ),
    [classes, contents]
  );
  return (
    <Grid container item xs={12} sm={12}>
      {titles.map(_renderTitleItem)}
      {contents.map(_renderContentItem)}
    </Grid>
  );
}

const isMemberOwnedToysTitles = ["姓名", "玩具"];
// ANCHOR 會員持有的玩具
const HatchingMemberOwnedToys = memo(({ eventId }) => {
  const useStyles = makeStyles({
    listPadding: {
      paddingTop: "3px",
      paddingBottom: "3px",
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
  });
  const classes = useStyles();
  const [excelLoading, setExcelLoading] = useState(false);

  const [getEventMemberEggs, { data, loading }] = useLazyQuery(
    GET_EVENT_MEMBEREGGS,
    {
      fetchPolicy: "network-only",
      onError() {
        return null;
      },
    }
  );

  useEffect(() => {
    if (eventId) {
      getEventMemberEggs({ variables: { eventId } });
    }
  }, [getEventMemberEggs, eventId]);

  const eventMemberEggs = useMemo(
    () => emptyArray(data?.eventMemberEggs),
    [data]
  );

  const isMemberOwnedToysContents = useMemo(() => {
    const newData = [];
    eventMemberEggs.forEach((item) => {
      const hasMember = newData.findIndex(
        (item2) => item2.member.id === item.member.id
      );
      if (hasMember > -1) {
        const hasToy = newData[hasMember].toys.findIndex(
          (item3) => item3.id === item.toy.id
        );
        if (hasToy > -1) {
          newData[hasMember].toys[hasToy].quantity += 1;
        } else {
          newData[hasMember].toys.push({ ...item.toy, quantity: 1 });
        }
      } else {
        newData.push({
          member: item.member,
          toys: [{ ...item.toy, quantity: 1 }],
        });
      }
    });
    return newData;
  }, [eventMemberEggs]);

  function _renderTitleItem(title) {
    return (
      <Grid item xs={3} sm={3}>
        <Typography>{title}</Typography>
      </Grid>
    );
  }
  const _renderContentItem = useCallback(
    (item) => (
      <Grid container item direction={"row"} xs={12}>
        {Object.keys(item).map((item2, index2) => {
          if (index2 === 0) {
            return (
              <Grid item xs={12} sm={3} classes={{ root: classes.listPadding }}>
                <Typography>{item[item2]?.fullName}</Typography>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={12} sm={9} classes={{ root: classes.listPadding }}>
                <Typography>
                  {emptyArray(item[item2])
                    .map((item3) => `${item3.product.name} * ${item3.quantity}`)
                    .join("、")}
                </Typography>
              </Grid>
            );
          }
        })}
        <Box
          style={{
            height: "100%",
            width: "100%",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Divider />
        </Box>
      </Grid>
    ),
    [eventMemberEggs]
  );
  const downloadList = useCallback(() => {
    setExcelLoading(true);
    const entozh = {
      fullName: "姓名",
      mobile: "電話",
    };
    const excelData = [];
    isMemberOwnedToysContents.forEach((item) => {
      const newDate = {
        fullName: item.member.fullName,
        mobile: item.member.mobile,
      };
      item.toys.forEach((item2) => {
        newDate[item2.product.name] = item2.quantity;
        entozh[item2.product.name] = item2.quantity;
      });
      excelData.push(newDate);
    });
    const newEntozh = {};
    const newExcelData = [];
    Object.keys(entozh).forEach((item) => {
      if (item !== "fullName" && item !== "mobile") {
        newEntozh[`${Object.keys(newEntozh).length - 1}`] = `品項${
          Object.keys(newEntozh).length - 1
        }`;
      } else {
        newEntozh[item] = entozh[item];
      }
    });
    excelData.forEach((item) => {
      const newData = {};
      Object.keys(item).forEach((item2) => {
        if (item2 !== "fullName" && item2 !== "mobile") {
          newData[
            `品項${Object.keys(newData).length - 1}`
          ] = `${item2} * ${item[item2]}`;
        } else {
          newData[item2] = item[item2];
        }
      });
      newExcelData.push(newData);
    });
    excelDownload(
      newEntozh,
      newExcelData,
      `${moment().format("YYYY-MM-DD")} 路遙圓創育成活動會員持有的玩具清單`
    )
      .then((result) => {
        if (result === "Finish") {
          setExcelLoading(false);
        }
      })
      .catch(() => {
        setExcelLoading(false);
      });
  }, [isMemberOwnedToysContents]);
  if (loading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Box minHeight={`350px`} width="100%">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onPress={downloadList}
            disabled={eventMemberEggs.length === 0 || excelLoading}
          >
            下載清單
          </Button>
        </Grid>
        {isMemberOwnedToysTitles.map(_renderTitleItem)}
        {isMemberOwnedToysContents.map(_renderContentItem)}
      </Grid>
    </Box>
  );
});

// NOTE 內容管理切換資料
const tabViewData = [
  "蛋種列表",
  "玩具列表",
  "抽選結果列表",
  "進化代碼",
  "孵化代碼",
  "遊戲說明",
];
// ANCHOR 內容管理切換
function HatchingContentTable({ eventId }) {
  const { control } = useForm({
    defaultValues: {
      select: 0,
    },
  });
  return (
    <Grid>
      <Controller
        control={control}
        name="select"
        render={({ field }) => (
          <TabView
            paper
            tabs={tabViewData}
            index={field.value}
            onChange={field.onChange}
          >
            <EggTypeManage eventId={eventId} />
            <ToyManage eventId={eventId} />
            <DrawingLotsResultManage eventId={eventId} />
            <FeedCodeManage eventId={eventId} />
            <HatchingCodeManage eventId={eventId} />
            <HatchingDescription eventId={eventId} />
          </TabView>
        )}
      />
    </Grid>
  );
}
