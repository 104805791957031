import FloatingIslandCultivationPage from "./FloatingIsland/CultivationPage";
import MysticIslandCultivationPage from "./MysticIsland/CultivationPage";
import DarkCityCultivationPage from "./DarkCity/CultivationPage";
import ParallelUniverseCultivationPage from "./ParallelUniverse/CultivationPage";
import RadishIslandCultivationPage from "./RadishIsland/CultivationPage";

export default function CultivationMainPage() {
  const version =
    sessionStorage.getItem("luyao_raisingEvent_version") || "FloatingIsland";

  if (version === "FloatingIsland") {
    return <FloatingIslandCultivationPage />;
  } else if (version === "MysticIsland") {
    return <MysticIslandCultivationPage />;
  } else if (version === "DarkCity") {
    return <DarkCityCultivationPage />;
  } else if (version === "ParallelUniverse") {
    return <ParallelUniverseCultivationPage />;
  } else if (version === "RadishIsland") {
    return <RadishIslandCultivationPage />;
  }

  return null;
}
