import { useCallback, useEffect, useMemo, memo } from "react";
// mui
import {
  Box,
  makeStyles,
  useTheme,
  Container,
  useMediaQuery,
} from "@material-ui/core";
// apollo
import { useLazyQuery, useQuery } from "@apollo/client";
import gql from "graphql-tag";
// helmet
import { Helmet } from "react-helmet";
// react-hook-form
import {
  useForm,
  useFormContext,
  useWatch,
  FormProvider,
} from "react-hook-form";
// react-router-dom
import { useHistory } from "react-router-dom";

// components
import { useAlert } from "../Alert";
import SwitchNavTabs from "../../component/special-event/SwitchNavTabs";
import ParallelUniverseSwitchNavTabs from "../../page/SpecialEvent/ParallelUniverse/component/SwitchNavTabs";
// utils
import emptyArray from "../../utils/emptyArray";
import LoadingModal from "../LoadingModal";
// zustand
import useTokenStore from "../../zustand/useTokenStore";
import useHatchingStore from "../../zustand/useHatchingStore";
import useHatchingOrderIdStore from "../../zustand/useHatchingOrderIdStore";
import "./index.css";

// SECTION apollo
// NOTE 活動
const GET_EVENT = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      # "類型"
      type
      ... on RaisingEvent {
        # "活動總券數"
        maxNumberOfTicket
        # "每人購買券數上限"
        maxNumberOfTicketPerPerson
        # "票價"
        ticketFare
        # "培育時間(目前為分鐘)"
        hatchingTime
        # "抽選方案"
        drawLotsPlans {
          id
          # "票數"
          ticket
          # "次數"
          times
        }
        # "可出貨時間"
        dateOfShipped
        # "結束時間"
        endTime
        # "餵食冷卻時間"
        feedingCoolDownTime
      }
    }
  }
`;
// NOTE 活動內容
const GET_EVENT_CONTENT = gql`
  query eventContent($eventId: Int!) {
    eventContent(eventId: $eventId)
  }
`;
// NOTE 訂單
const GET_ORDER = gql`
  query order($id: Int!) {
    order(id: $id) {
      id
      # "品項"
      items {
        id
        # "數量"
        amount
      }
      # "付款紀錄"
      payments {
        id
        # "付款成功"
        success
      }
    }
  }
`;
// NOTE 會員
const GET_MEMBER = gql`
  query member {
    member {
      id
      # "券數"
      tickets
    }
  }
`;
// NOTE 會員蛋列表
const GET_MEMBER_EGGS = gql`
  query memberEggs($eventId: Int) {
    memberEggs(eventId: $eventId, latest: true, hatched: false) {
      # "會員蛋"
      contents {
        id
        # "名稱"
        name
        # "蛋種"
        eggType {
          id
          # "名稱"
          name
          # "蛋gif"
          gifUrl
          # "簡介"
          introduction
        }
        # "最後餵食時間"
        fedAt
        # "建立時間"
        createdAt
      }
    }
    memberToys: memberEggs(eventId: $eventId, hatched: true) {
      # "會員玩具"
      contents {
        id
        # "名稱"
        name
        # "玩具"
        toy {
          id
          # "商品"
          product {
            id
            # "名稱"
            name
          }
          # "玩具gif"
          gifUrl
          # "簡介"
          introduction
        }
        # "孵化時間"
        hatchedAt
        # "建單"
        createdOrder
      }
    }
  }
`;
// !SECTION

// ANCHOR Banner
export default function BackgroundContainer({ children }) {
  const version =
    sessionStorage.getItem("luyao_raisingEvent_version") || "FloatingIsland";
  const defineBackgroundImage = () => {
    if (version === "FloatingIsland") {
      return 'url("/img/special/special-background-full.webp")';
    } else if (version === "MysticIsland") {
      return 'url("/img/special/event-2/special-background-full.jpg")';
    } else if (version === "DarkCity") {
      return 'url("/img/special/event-dark-city/底圖.jpg")';
    } else if (version === "ParallelUniverse") {
      return 'url("/img/special/parallel-universe/一顆牙育成長圖.png")';
    } else if (version === "RadishIsland") {
      return 'url("/img/special/radish-island/一顆牙育成長圖.png")';
    }
  };
  const theme = useTheme();
  const isPc = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles({
    // bannerContainer
    bannerContainer: {
      padding: isPc ? "54px 16px" : "28px 8px",
      inset: 0,
    },
    // banner
    imageOutsideBox: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      // paddingTop: isPc ? "56.25%" : "70%",
      height: "100%",
      backgroundImage: defineBackgroundImage(),
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top",
      // minHeight: "100vh",
    },
  });
  const classes = useStyles();
  const form = useForm({
    defaultValues: {
      event: null,
      drawLotsPlans: [],
      clickButton: "ticket",
      quantity: 1,
      selectDrawEgg: null,
      eventLoading: false,
      eventRefetch: false,
      eventContentLoading: false,
      memberLoading: false,
      memberRefetch: false,
      memberEggsLoading: false,
      memberEggsRefetch: false,
      acceptRules: false,
      enterCode: "",
      name: "",
      order: null,
      myTickets: 0,
      memberEggs: [],
      memberToys: [],
      selectItems: null,
      myCultivationClickButton: "hatchingEggs",
      changeNameText: "",
      getMemberEggs: [],
    },
  });
  return (
    <>
      <Helmet>
        <title>育成專區｜路遙圓創</title>
      </Helmet>
      <Box
        id="BackgroundContainer"
        className={`${classes.imageOutsideBox} game-area`}
      >
        <FormProvider {...form}>
          <FetchHatchingData />
          <FetchMemberTickets />
          <FetchMemberEggs />
          <FetchHatchingOrderData />
          {/* <LoggedOutAction /> */}
          <Container maxWidth="md" className={classes.bannerContainer}>
            <SwitchNavTabsContain />
            <FetchLoading>{children}</FetchLoading>
          </Container>
        </FormProvider>
      </Box>
    </>
  );
}
/** - SwitchNavTabs */
const SwitchNavTabsContain = memo(function SwitchNavTabsContain() {
  const theme = useTheme();
  const isPc = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const version =
    sessionStorage.getItem("luyao_raisingEvent_version") || "FloatingIsland";
  const _renderSwitchNavTabs = useMemo(() => {
    if (version === "ParallelUniverse") {
      return <ParallelUniverseSwitchNavTabs />;
    }
    return <SwitchNavTabs />;
  }, [version]);
  return (
    <Box
      style={{
        paddingBottom:
          version !== "ParallelUniverse"
            ? isPc
              ? "60px"
              : isTablet
              ? "52px"
              : "32px"
            : "0px",
      }}
    >
      {_renderSwitchNavTabs}
    </Box>
  );
});
// ANCHOR fetch loading
function FetchLoading({ children }) {
  const eventLoading = useWatch({ name: "eventLoading" });
  const eventContentLoading = useWatch({ name: "eventContentLoading" });
  const memberLoading = useWatch({ name: "memberLoading" });
  const memberEggsLoading = useWatch({ name: "memberEggsLoading" });
  const isLoading = useMemo(
    () =>
      eventLoading || eventContentLoading || memberLoading || memberEggsLoading,
    [eventLoading, eventContentLoading, memberLoading, memberEggsLoading]
  );
  return (
    <>
      <LoadingModal loading={isLoading} />
      {children}
    </>
  );
}
// ANCHOR 抓取育成資料
function FetchHatchingData() {
  const { notice } = useAlert();
  const history = useHistory();
  const { setValue } = useFormContext();
  const eventRefetch = useWatch({ name: "eventRefetch" });

  const [getEventContent, { loading: eventContentLoading }] = useLazyQuery(
    GET_EVENT_CONTENT,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted({ eventContent }) {
        setTimeout(() => {
          if (eventContent) {
            setTimeout(() => {
              setValue("eventContent", eventContent);
              setValue("eventContentLoading", false);
            }, 0);
          } else {
            setValue("eventContentLoading", false);
          }
        }, 0);
      },
      onError() {
        setValue("eventContentLoading", false);
        return null;
      },
    }
  );
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  useEffect(() => {
    if (id) {
      sessionStorage.setItem("luyao_raisingEvent_id", id);
    }
  }, [id]);

  const eventId = sessionStorage.getItem("luyao_raisingEvent_id");

  const { refetch, loading } = useQuery(GET_EVENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      id: Number(id) || Number(eventId),
    },
    onCompleted({ event }) {
      setTimeout(() => {
        if (event) {
          const { drawLotsPlans, ...otherData } = event;
          if (otherData.type === "RAISING_EVENT") {
            getEventContent({ variables: { eventId: event.id } });
            setTimeout(() => {
              setValue("event", otherData);
              setValue("drawLotsPlans", emptyArray(drawLotsPlans));
              setValue("eventRefetch", false);
            }, 0);
          } else {
            setValue("eventRefetch", false);
            notice("路徑錯誤，請確認網址是否正確。");
            sessionStorage.removeItem("luyao_raisingEvent_id");
            history.replace("/");
          }
        } else {
          setValue("eventRefetch", false);
          notice("活動尚未開始，敬請期待。");
          sessionStorage.removeItem("luyao_raisingEvent_id");
          history.replace("/");
        }
      }, 0);
    },
    onError() {
      setValue("eventRefetch", false);
      notice("活動尚未開始，敬請期待。");
      sessionStorage.removeItem("luyao_raisingEvent_id");
      history.replace("/");
      return null;
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setValue("eventLoading", loading);
    }, 0);
  }, [loading, setValue]);

  useEffect(() => {
    setTimeout(() => {
      setValue("eventContentLoading", eventContentLoading);
    }, 0);
  }, [eventContentLoading, setValue]);

  useEffect(() => {
    if (eventRefetch && refetch) {
      refetch();
    }
  }, [eventRefetch, refetch]);
  return null;
}
// ANCHOR 抓取會員券
function FetchMemberTickets() {
  const { setValue } = useFormContext();
  const memberRefetch = useWatch({ name: "memberRefetch" });
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const hatchingOrderId = useHatchingOrderIdStore(
    useCallback((state) => state.hatchingOrderId, [])
  );
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  const eventId = sessionStorage.getItem("luyao_raisingEvent_id");

  const { refetch, loading } = useQuery(GET_MEMBER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ member }) {
      setTimeout(() => {
        if (member) {
          if (memberToken) {
            setTimeout(() => {
              setValue("myTickets", member.tickets);
              setValue("memberRefetch", false);
            }, 300);
          } else {
            setTimeout(() => {
              setValue("myTickets", 0);
              setValue("memberRefetch", false);
            }, 100);
          }
        } else {
          setValue("memberRefetch", false);
        }
      }, 0);
    },
    onError() {
      setValue("memberRefetch", false);
      return null;
    },
  });

  useEffect(() => {
    if ((id || eventId) && refetch) {
      refetch();
    }
  }, [id, eventId, refetch]);

  useEffect(() => {
    if (hatchingOrderId && refetch) {
      setTimeout(() => {
        refetch();
      }, 0);
    }
  }, [hatchingOrderId, refetch]);

  useEffect(() => {
    setTimeout(() => {
      setValue("memberLoading", loading);
    }, 0);
  }, [loading, setValue]);

  useEffect(() => {
    const fetchMemberTicketsPreviousToken =
      useHatchingStore.getState().fetchMemberTicketsPreviousToken;
    if (
      (fetchMemberTicketsPreviousToken !== memberToken || memberRefetch) &&
      refetch
    ) {
      setTimeout(() => {
        refetch();
      }, 0);
    }
    useHatchingStore.getState().setFetchMemberTicketsPreviousToken(memberToken);
  }, [memberToken, memberRefetch, refetch]);

  return null;
}
// ANCHOR 抓取會員蛋或玩具
function FetchMemberEggs() {
  const { setValue } = useFormContext();
  const memberEggsRefetch = useWatch({ name: "memberEggsRefetch" });
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const hatchingOrderId = useHatchingOrderIdStore(
    useCallback((state) => state.hatchingOrderId, [])
  );
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  const eventId = sessionStorage.getItem("luyao_raisingEvent_id");

  const { refetch, loading } = useQuery(GET_MEMBER_EGGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      eventId: Number(id) || Number(eventId),
    },
    onCompleted({ memberEggs, memberToys }) {
      setTimeout(() => {
        if (memberEggs && memberToys) {
          if (memberToken) {
            setTimeout(() => {
              setValue("memberEggs", memberEggs.contents);
              setValue("memberToys", memberToys.contents);
              setValue("memberEggsRefetch", false);
            }, 300);
          } else {
            setTimeout(() => {
              setValue("memberEggs", []);
              setValue("memberToys", []);
              setValue("memberEggsRefetch", false);
            }, 0);
          }
        } else {
          setTimeout(() => {
            setValue("memberEggs", []);
            setValue("memberToys", []);
            setValue("memberEggsRefetch", false);
          }, 0);
        }
      }, 0);
    },
    onError() {
      setValue("memberEggsRefetch", false);
      return null;
    },
  });

  useEffect(() => {
    if (hatchingOrderId && refetch) {
      setTimeout(() => {
        refetch();
      }, 0);
    }
  }, [hatchingOrderId, refetch]);

  useEffect(() => {
    setTimeout(() => {
      setValue("memberEggsLoading", loading);
    }, 0);
  }, [loading, setValue]);

  useEffect(() => {
    const fetchMemberEggsPreviousToken =
      useHatchingStore.getState().fetchMemberEggsPreviousToken;
    if (
      (fetchMemberEggsPreviousToken !== memberToken || memberEggsRefetch) &&
      refetch
    ) {
      setTimeout(() => {
        refetch();
      }, 0);
    }
    useHatchingStore.getState().setFetchMemberEggsPreviousToken(memberToken);
  }, [memberToken, memberEggsRefetch, refetch]);
  return null;
}
// ANCHOR 抓取購買券的訂單
function FetchHatchingOrderData() {
  const { setValue } = useFormContext();
  const hatchingOrderId = useHatchingOrderIdStore(
    useCallback((state) => state.hatchingOrderId, [])
  );

  const [getOrder] = useLazyQuery(GET_ORDER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ order }) {
      if (order) {
        setTimeout(() => {
          const { payments, ...otherData } = order;
          const hasPayed = emptyArray(payments).find((item) => item.success);
          const directedToPayment =
            useHatchingStore.getState().directedToPayment;
          if (hasPayed) {
            setTimeout(() => {
              setValue("order", otherData);
            }, 0);
          } else if (!directedToPayment) {
            useHatchingOrderIdStore.getState().cleanHatchingOrderId();
          }
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (hatchingOrderId) {
      getOrder({ variables: { id: hatchingOrderId } });
    }
  }, [hatchingOrderId, getOrder]);

  return null;
}
// ANCHOR 被登出後的動作
// function LoggedOutAction() {
//   const { setValue } = useFormContext();
//   const memberToken = useTokenStore(
//     useCallback((state) => state.memberToken, [])
//   );

//   useEffect(() => {
//     const handleClick = () => {
//       if (!memberToken) {
//         setValue("myTickets", 0);
//         setValue("memberEggs", []);
//         setValue("memberToys", []);
//         setValue("memberRefetch", false);
//         setValue("memberEggsRefetch", false);
//       }
//     };
//     window.addEventListener("click", handleClick, {
//       passive: true,
//     });
//     return () => {
//       window.removeEventListener("click", handleClick, {
//         passive: true,
//       });
//     };
//   }, [memberToken, setValue]);
//   return null;
// }
